import { ArrowLeft, ArrowRight, Xmark } from "iconoir-react";
import OnBoardingStep1 from "../../asset/image/onboardingStep1.png";
import InputField from "../../component/custom-form/InputField";
import DialogBox from "../../component/DialogBox";
import { useEffect } from "react";
import { toast } from "react-toastify";

const EditInterest = ({ type, value, onChange, handleClose, handleNext, enableOther, setEnableOther, otherText, setOtherText }) => {
    const founderOptions = ['Startups', 'Fundraising', 'Bootstrapping', 'Product', 'Mental Health', 'Sales', 'Personal Branding'];
    const studentOptions = ['Jobs', 'Internship', 'Exams', 'Career', 'Placements', 'Startup', 'Interview', 'Freelancing'];
    const creatorOptions = ['Content', 'Engagement', 'Fame', 'Personal Branding', 'Authenticity', 'Journey', 'Finance'];
    const professionalOptions = ['Productivity', 'Mental Health', 'Growth', 'Leadership', 'Jobs', 'Personal Branding', 'Work Life Balance'];
    const freeLancerOptions = ['Clients', 'Sales', 'Networking', 'Finance', 'Personal Branding', 'Side hustle', 'Skills'];
    const otherOptions = ['Mindset', 'Community', 'Career', 'Mental Health', 'Growth', 'Travel', 'Personal Branding'];

    const optionMap = {
        Founder: founderOptions,
        Stundet: studentOptions,
        Creator: creatorOptions,
        'Working professional': professionalOptions,
        Freelancer: freeLancerOptions,
        other: otherOptions
    }

    const options = optionMap[type] || optionMap.other;
    const maxLength = 60;


    useEffect(() => {
        // mutate()
        const unmatched = value.filter(element => !options.includes(element));
        if (unmatched.length > 0) {
            setOtherText(unmatched.join(', '))
            setEnableOther(true);
        }

    }, [])

    const handleClick = (currentVal) => {
        const prevInterests = [...value];
        if (prevInterests.includes(currentVal)) {
            const filteredInterests = prevInterests.filter((item) => item !== currentVal);
            onChange('interest', filteredInterests)
        } else {
            onChange('interest', [...prevInterests, currentVal]);
        }
    }

    const handleOtherClick = () => {
        if (enableOther) {
            setOtherText('');
            setEnableOther(false);
        } else {
            setEnableOther(true);
        }
    }

    const handleChange = (event) => {
        setOtherText(event.target.value);
    }

    const handleNextClick = () => {
        const interests = [...value];
        const commonElements = options.filter(item => interests.includes(item));
        const otherInterests = otherText ? otherText.split(',').map((item) => item.trim()) : [];

        let finalArray = [...new Set([ ...commonElements, ...otherInterests])];

        onChange('interest', finalArray);

        if(finalArray.length <= 0) {
           toast.error("Kindly select at least one option.");
           return;
        }

        handleNext({
            interest: finalArray
        });
    }

    return (
        <DialogBox>
            <div className="fixed inset-0 flex items-center justify-center bg-custom-light backdrop-blur-sm bg-opacity-50 z-50">
                <div className="bg-gray-moon relative px-9 py-9 rounded-3xl min-h-[600px]">

                    <div className="absolute h-full left-0 top-0">
                        <img className="h-full w-full object-contain" src={OnBoardingStep1} alt="onboarding-step-3" />
                    </div>
                    <div className="flex justify-end items-center">
                        <div className="text-btn small">
                            <Xmark onClick={handleClose} className="cursor-pointer" />
                        </div>
                    </div>
                    <div className="flex [min-height:337px]">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex flex-col">
                            <p className="text-headingTwo bg-hover-gradient-1 bg-clip-text text-transparent">Edit Recommendations</p>
                            <p className="mt-1 text-subHeadingThree text-gray-6">I want to post about _________________</p>
                            <div className="flex flex-wrap items-center gap-2.5 mt-8">
                                {
                                    options.map((option) => (
                                        <button key={option} className={`outlined-btn ${value.includes(option) ? 'active' : ''}`} onClick={() => { handleClick(option) }}>
                                            {option}
                                        </button>
                                    ))
                                }

                                <button className={`outlined-btn ${enableOther ? 'active' : ''}`} onClick={handleOtherClick}>
                                    Other
                                </button>
                            </div>
                            {
                                enableOther && (
                                    <div className="mt-4">
                                        <InputField name='otherText' maxLength={maxLength} value={otherText} onChange={handleChange} />
                                    </div>
                                )
                            }
                            <div className="mt-10">
                                <p className="text-bodyTwoBold text-gray-5">
                                    What would you like to post about? Select as many as you want, click on ‘other’ to add more (separate by commas)
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <button className="absolute px-6 filled-btn text-button right-6 bottom-6 icon-btn text-white" disabled={!value} onClick={handleNextClick}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </DialogBox>
    )
}
export default EditInterest;