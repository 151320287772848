import { useEffect, useState } from "react";
import SoloLogo from "../../asset/svg/SoloLogo";
import PlanTimer from "../../asset/svg/planTimer.svg";
import SelectBox from "../../component/custom-form/SelectBox";
import CustomTable from "../../component/CustomTable";
import PlanCard from "../../component/PlanCard";
import SegmentedBtn from "../../component/SegmentedBtn";
import MainLayout from "../../layout/main";
import invoiceDummy from "../../_mock/invoice";
import { Download, Xmark } from "iconoir-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices, fetchPlans, paymentVerified, subscribePlan } from "../../services/payment.service";
import LoaderGif from "../../asset/gif/loader.gif";
import moment from "moment";
import CancelSubscription from "./CancelSubscription";
import { trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import FreePlanCard from "../../component/FreePlanCard";
import { toast } from "react-toastify";
import { RAZORPAY_KEY } from "../../utils/config";
import { updateSubscription } from "../../redux/slice/auth.slice";
import PaymentSuccessModel from "../payment/PaymentSucessModel";

const options = [
    {
        label: 'Funny',
        value: 'funny'
    },
    {
        label: 'Insightful',
        value: 'insightful'
    },
    {
        label: 'Share worthy',
        value: 'shareworthy'
    },
]

function getDayDifference(billingStart, billingEnd) {
    // Convert timestamps to JavaScript Date objects
    const startDate = new Date(billingStart * 1000);  // Convert from seconds to milliseconds
    const endDate = new Date(billingEnd * 1000);      // Convert from seconds to milliseconds

    // Calculate the difference in time (milliseconds) and convert it to days
    const timeDifference = endDate - startDate;
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
}

const columns = [
    {
        name: 'Invoice Date',
        selector: row => `${moment.unix(row.created_at).format("DD MMM'YY")}`,
    },
    {
        name: 'Tenure',
        selector: row => getDayDifference(row.billing_start, row.billing_end) > 31 ? 'Yearly' : 'Monthly',
    },
    {
        name: 'Amount',
        selector: row => `${row.currency_symbol}${row.amount / 100}`,
    },
    {
        name: 'Action',
        selector: (row) => {
            return (
                <div>
                    <a href={row.short_url} target="_blank" className="tertiary-btn small" onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.INVOICE_VIEWED)
                    }}>
                        <Download />
                        View Invoice
                    </a>
                </div>
            )
        },
    },
];

const Plan = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const endDate = subscription.endDate;
    const now = moment(); // Current date
    const diffInDays = moment(endDate).diff(now, 'days');
    const isFreeTrial = subscription.isFreeTrial;
    const tabs = ['Yearly', 'Monthly'];
    const [filter, setFilter] = useState({});

    const [activeIndex, setActiveIndex] = useState(0);
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false);

    // const [currentPlan, setCurrentPlan] = useState(null);
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [openSuccess, setOpenSuccess] = useState(false);


    const { data, isLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: () => fetchPlans(),
        staleTime: Infinity,
    });

    const query = {
        id: subscription.subscriptionId
    }
    const { data: invoiceData, isLoading: invoiceIsLoading } = useQuery({
        queryKey: ['invoices', query],
        queryFn: ({ queryKey }) => fetchInvoices(queryKey[1]),
        staleTime: Infinity,
        retry: false,
        enabled:!isFreeTrial
    });

    // Extract monthly and yearly plans from fetched data
    const monthlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'monthly')?.sort((a, b) => a.amount - b.amount) || [];
    const yearlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'yearly')?.sort((a, b) => a.amount - b.amount) || [];

    useEffect(() => {
        if (subscription.billingPeriod === 'monthly') {
            setActiveIndex(1);
            // setCurrentPlan(monthlyPlan[0]);
        } else {
            setActiveIndex(0);
            // setCurrentPlan(yearlyPlan[0]);
        }
    }, [data]);

    const handleChange = (index) => {
        // if (index === 0 && monthlyPlan.length > 0) {
        //     setCurrentPlan(monthlyPlan[0]);
        // } else if (yearlyPlan.length > 0) {
        //     setCurrentPlan(yearlyPlan[0]);
        // }
        setActiveIndex(index);
    };

    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.SETTINGS_VIEWED, {
            "Page viewed": "Plan & Billing, Preferences"
        })
    }, [])


    const countDown = `${diffInDays + 1} Day${diffInDays + 1 === 1 ? '' : 's'} Left`;
    const currentPlans = activeIndex ? monthlyPlan : yearlyPlan;

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId) {
                toast.success(data.message);
                return;
            }

            const options = {
                key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                subscription_id: data.data.subscriptionId,
                name: 'SuperPen',
                description: 'Subscription Payment',
                handler: async (response) => {
                    try {
                        const result = await paymentVerified(response);
                        setResponse(result.data)
                        dispatch(updateSubscription(result.data))
                        setOpenSuccess(true);
                    } catch (error) {
                        console.log(error);
                        toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
                    }
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment Model Closed');
                    },
                },
                theme: {
                    color: '#7145FD',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handlePayment = (planId) => {
        if (planId) {
            mutate({
                planId
            });
        }
    };
    const currencySymbol = monthlyPlan[0]?.currency === 'INR' ? '₹' : '$';
    const currentAmount = response?.subscription?.amountPaid;

    if (isLoading) {
        return (
            <MainLayout>
                <div className="w-full h-full flex items-center justify-center">
                    <img src={LoaderGif} width={200} alt='loading' />
                </div>
            </MainLayout>
        )
    }
    return (
        <>
            {
                openCancelSubscription && (
                    <CancelSubscription onClose={() => {
                        setOpenCancelSubscription(false);
                    }} />
                )
            }
            {openSuccess && (
                <PaymentSuccessModel
                    amount={`${currencySymbol}${currentAmount}`}
                // onClose={() => {
                //     setOpenSuccess(false);
                // }}
                />
            )}

            <MainLayout>
                <div className="rounded-2xl px-5 py-8 bg-star-light3">
                    <p className="text-subHeadingTwoBold text-space-default">Your Plan & Billing</p>
                    <p className="text-bodyTwo bg-clip-text text-transparent bg-heading-gradient-3">Manage your billing information, subscription and invoices</p>
                    {
                        isFreeTrial && diffInDays >= 0 && (
                            <div className="relative py-4 px-8 bg-gray-moon flex items-center justify-between rounded-2xl mt-7">
                                <div>
                                    <p>
                                        <span className="text-subHeadingOne text-star-default">{countDown} </span>
                                        <span className="text-subHeadingTwo text-gray-5">of your FREE Trial</span>
                                    </p>
                                    {/* <p className="text-bodyOne mt-2.5 text-gray-5">Your plan will be activated from <span className="text-subHeadingThreeBold ml-3 text-space-default">{moment(endDate).format("DD MMM'YY")}</span> </p> */}
                                </div>
                                <div className="absolute  right-0 bottom-0">
                                    <img src={PlanTimer} alt="plan-timer" />
                                </div>
                            </div>
                        )
                    }

                    <div className="flex flex-col mt-7 items-center justify-center">
                        <SegmentedBtn list={tabs} onChange={handleChange} activeIndex={activeIndex} />
                        <p className="text-bodyTwo mt-2 text-gray-5"><b>Save 30%</b> with yearly billing</p>
                    </div>
                    <div className="mt-6 flex gap-5">
                        <FreePlanCard tenure={activeIndex ? 'month' : 'year'} />
                        {
                            currentPlans.map((item, index) => (
                                <PlanCard key={index} index={index} {...item} isActive={subscription.planId === item._id} handlePayment={handlePayment} isPending={isPending} />
                            ))
                        }

                    </div>
                    <div className="flex justify-between mt-7">
                        <div>
                            <p className="text-bodyOneBold text-gray-6">Subscription History</p>
                            <p className="text-bodyTwo text-gray-5">Download your previous subscription invoices</p>
                        </div>
                        <div>
                            {/* <SelectBox
                            value={filter}
                            label="Recent"
                            options={options}
                            onRemoveFilter={() => { setFilter({}) }}
                            onChange={(val) => { setFilter(val) }}
                        /> */}
                        </div>
                    </div>
                    <div className="mt-5">
                        <CustomTable
                            data={invoiceData?.data?.items || []}
                            columns={columns}
                            totalRows={10}
                            handlePageChange={() => { }}
                            handlePerRowsChange={() => { }}
                        />
                    </div>
                    {
                        !isFreeTrial && (
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-bodyOneBold text-venus-default">Danger Zone</p>
                                    <p className="text-bodyTwo text-gray-5 mt-2">Request to cancel your plan subscription</p>
                                </div>
                                <div className="my-10">
                                    <button className="text-btn small white" disabled={subscription.status === 'cancelled'} onClick={() => {
                                        trackEvent(MIXPANEL_EVENTS.CANCEL_REQUEST);
                                        setOpenCancelSubscription(true);
                                    }}>
                                        <Xmark />
                                        Request Cancellation
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </MainLayout>
        </>
    )
}
export default Plan;