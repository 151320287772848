import { useQuery } from "@tanstack/react-query";
import { getbookmarkPosts } from "../../services/bookmark.service";
import { ArrowLeft, DiceThree, Filter } from "iconoir-react";
import LoaderGif from "../../asset/gif/loader.gif";
import MicstageIcon from "../../asset/svg/MicStageIcon";
import NotFoundImage from "../../asset/svg/notFound.svg";
import PenNibIcon from "../../asset/svg/PenNibIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import SelectBox from "../../component/custom-form/SelectBox";
import MasonaryLayout from "../../component/MasonaryLayout";
import PostCard from "../../component/PostCard";
import MainLayout from "../../layout/main";
import { MIXPANEL_EVENTS } from '../../utils/mixpanel/eventType';
import ClockCountDown from '../../asset/svg/ClockCountDown';
import { useEffect, useState } from "react";
import moment from "moment";
import { trackEvent } from "@intercom/messenger-js-sdk";
import { isObjectEmpty } from "../../utils/helper";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";



const likesOption = [
    {
        label: '100 - 200',
        value: {
            min: 100,
            max: 200
        }
    },
    {
        label: '201 - 500',
        value: {
            min: 201,
            max: 500
        }
    },
    {
        label: '501 - 1000',
        value: {
            min: 501,
            max: 1000
        }
    },
    {
        label: '1000+',
        value: {
            min: 1000,
            max: undefined
        }
    },
]

const contentToneOption = [
    {
        label: 'Funny',
        value: 'funny'
    },
    {
        label: 'Insightful',
        value: 'insightful'
    },
    {
        label: 'Share worthy',
        value: 'shareworthy'
    },
]

const contentLengthOption = [
    {
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    },
    {
        label: 'Medium',
        value: {
            min: 75 * 5,
            max: 200 * 5
        }
    },
    {
        label: 'Long',
        value: {
            min: 200 * 5,
        }
    },
]

const contentTimeOption = [
    {
        label: 'Last 3 Months',
        value: {
            startDate: moment().subtract(3, 'months').toDate(),
            endDate: null,
        }
    },
    {
        label: '3 Months to 1 Year',
        value: {
            startDate: moment().subtract(12, 'months').toDate(),
            endDate: moment().subtract(3, 'months').toDate()
        }
    },
    {
        label: 'Over 1 Year Ago',
        value: {
            startDate: null,
            endDate: moment().subtract(1, 'year').toDate() // No end date, assuming open-ended
        }
    },
];

const BookmarkPosts = () => {

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');
    const [likeFilter, setLikeFilter] = useState({});
    const [contentToneFilter, setContentToneFilter] = useState({});
    const [contentTimeFilter, setContentTimeFilter] = useState({});
    const [collectionName, setCollectionName] = useState('');
    const [postCount, setPostCount] = useState(0);
    const [isUnstuck, setIsUnstuck] = useState(false);
    const [collectionId, setcollectionId] = useState('');
    const [contentLengthFilter, setContentLengthFilter] = useState({
        label: 'Short',
        value: {
            min: 0,
            max: 75 * 5
        }
    });

    const location = useLocation().state;

    useEffect(() => {
        const collectionId = new URLSearchParams(location).get("collectionId");
        const collectionName = new URLSearchParams(location).get("name");
        const count = new URLSearchParams(location).get("count");
        setPostCount(count);
        setCollectionName(collectionName);
        setcollectionId(collectionId);
    }, []);

    const query = { collectionId };
    const { data: collectionList = [], isLoading, refetch } = useQuery({
        queryKey: ['bookmark-posts', query],
        queryFn: ({ queryKey }) => getbookmarkPosts(queryKey[1]),
    });

    const resetAllFilter = () => {
        trackEvent(MIXPANEL_EVENTS.FILTERS_REMOVED);
        setLikeFilter({});
        setContentToneFilter({});
        setContentLengthFilter({});
        setContentTimeFilter({});
    }


    const activeFilter = Object.keys(likeFilter).length || Object.keys(contentLengthFilter).length || Object.keys(contentToneFilter).length
    const filterCount = !isObjectEmpty(likeFilter) + !isObjectEmpty(contentToneFilter) + !isObjectEmpty(contentLengthFilter);

    const trackFilterEvent = (eventName, name, value) => {
        trackEvent(MIXPANEL_EVENTS.FILTER_CLICKED, {
            "Filter type": name
        })
        trackEvent(eventName, {
            [`${name} Filter`]: value,
        });
    }

    const handleUnStick = () => {
        setIsUnstuck(true);
    }

    const metaData = {
        search: searchText,
        defaultRecommenedPost: searchText ? false : true,
        postTone: contentToneFilter?.value
    }

    return (
        <MainLayout>
            <div className="rounded-2xl mt-2  py-14 px-4 relative bg-star-light3 min-h-screen">

                <div>
                    <button
                        className="text-btn small"
                        type="button"
                        onClick={() => navigate(`/bookmarks`)}
                    >
                        <ArrowLeft /> Back
                    </button>

                    <div className="flex items-center gap-x-2">
                        <p className="text-headingThree text-space-default">{collectionName}</p> | <p className="text-subHeadingTwo text-gray-6">{postCount} Posts</p>
                    </div>
                </div>

                <div className={`flex bg-star-light3 p-4 items-center justify-center`}>

                    {/* <div className="flex mt-4 items-center gap-2">
                        <SelectBox
                            value={likeFilter}
                            icon={<ThumbsUpIcon />}
                            label="Likes"
                            options={likesOption}
                            onRemoveFilter={() => { setLikeFilter({}) }}
                            onChange={(val) => {
                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_LIKES, 'Likes', val.label);
                                setLikeFilter(val);
                            }}
                        />
                        <SelectBox
                            value={contentToneFilter}
                            icon={<MicstageIcon />}
                            label="Content Tone"
                            options={contentToneOption}
                            onRemoveFilter={() => { setContentToneFilter({}) }}
                            onChange={(val) => {
                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_TONE, 'Tone', val.label);
                                setContentToneFilter(val);
                            }}
                        />
                        <SelectBox
                            value={contentLengthFilter}
                            icon={<PenNibIcon />}
                            label="Length"
                            options={contentLengthOption}
                            onRemoveFilter={() => { setContentLengthFilter({}) }}
                            onChange={(val) => {
                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_LENGTH, 'Length', val.label);
                                setContentLengthFilter(val);
                            }}
                        />
                        <SelectBox
                            value={contentTimeFilter}
                            icon={<ClockCountDown />}
                            label="Time Period"
                            options={contentTimeOption}
                            onRemoveFilter={() => { setContentTimeFilter({}) }}
                            onChange={(val) => {
                                trackFilterEvent(MIXPANEL_EVENTS.FILTER_TIME, 'Time Period', val.label);
                                setContentTimeFilter(val);
                            }}
                        />
                    </div> */}
                </div>
                <div className="p-4">
                    {
                        isLoading && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={LoaderGif} width={200} alt="loading" />
                                <p className="text-bodyThreeBold text-star-light">Hang tight, Bookmarks is on the way!</p>
                            </div>
                        )
                    }
                    {
                        !isLoading && collectionList?.length === 0 && (
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={NotFoundImage} alt="notFound" />
                                <p className="text-bodyThreeBold text-star-light text-center">No bookmarked posts found. Please bookmark your favourite posts</p>
                            </div>
                        )
                    }
                    {
                        !isLoading && (
                            <MasonaryLayout>
                                {/* {console.log(collectionList)} */}
                                {
                                    collectionList.map((postItem) => (
                                        <div key={postItem.postId} className="flex-1">
                                            <PostCard {...postItem} postType={contentToneFilter?.value} metaData={metaData} />
                                        </div>
                                    ))
                                }
                            </MasonaryLayout>
                        )
                    }
                </div>
            </div>
        </MainLayout >

    )
}

export default BookmarkPosts;