import { store } from "../redux/store";

const PERMISSIONS = {
    AI_GENERATE: 'AI_GENERATE'
}


export const hasPermission = (permissionName) => {
    const storeState = store.getState();
    const permissions = storeState?.auth?.subscriptionLimit?.permissions || [];

    return permissions.some((perm) => {
        // console.log(perm.name, permissionName);
        return perm.name === permissionName;
    });
};

export default PERMISSIONS;