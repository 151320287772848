const LinkedinAvatar = ({ width = '20', height = '20', src, alt = 'profile_image' }) => {
    if (src) {
        return (
            <div className={`rounded-full overflow-hidden`} style={{
                width: `${width}px`,
                height: `${height}px`,
                minHeight:`${height}px`,
                maxHeight:`${height}px`,
                minWidth:`${width}px`,
                maxWidth:`${width}px`,
            }}>
                <img src={src} alt={alt} className="w-full h-full" />
            </div>
        )
    }
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 48 49"
        fill="none"
    >
        <circle cx="24" cy="24.5" r="24" fill="#E7E2DC" />
        <mask id="mask0_568_16221" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="49">
            <circle cx="24" cy="24.5" r="24" fill="#E7E2DC" />
        </mask>
        <g mask="url(#mask0_568_16221)">
            <ellipse cx="23.9998" cy="42.7394" rx="18.24" ry="10.56" fill="#9DB2C8" />
        </g>
        <circle cx="24" cy="24.5" r="12" fill="#788EA5" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.1648 33.2942C30.0231 35.2835 27.1536 36.5 24.0001 36.5C20.8464 36.5 17.9769 35.2835 15.8352 33.2941C18.2916 32.581 21.0648 32.1797 23.9998 32.1797C26.935 32.1797 29.7083 32.5811 32.1648 33.2942Z" fill="#57677A" />
    </svg>
    )
}
export default LinkedinAvatar;