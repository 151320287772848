import { createSlice } from '@reduxjs/toolkit';
import { setUserProperties } from '../../utils/mixpanel';

const initialState = {
  userId: '',
  name: '',
  email: '',
  onboardingStep: 1,
  isEmailVerified: false,
  isLinkedinVerified: false,
  profilePicture: '',
  role: '',
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
  occupation: '',
  interest: [],
  createdAt: '',
  subscription: null,
  subscriptionLimit: null,
  locationDetail: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { user, tokens, subscription, subscriptionLimit } = action.payload;
      state.userId = user._id;
      state.createdAt = user.createdAt;
      state.name = user.name;
      state.email = user.email;
      state.isEmailVerified = user.isEmailVerified;
      state.isLinkedinVerified = user.isLinkedinVerified;
      state.profilePicture = user.profilePicture;
      state.role = user.role;
      state.isLoggedIn = true;
      state.accessToken = tokens.access.token;
      state.refreshToken = tokens.refresh.token;
      state.occupation = user.occupation;
      state.interest = user.interest;
      state.locationDetail = user.locationDetail;

      // Calculate onboarding step based on presence of occupation, and interest
      let onboardingStep = 1;
      if (user.occupation) onboardingStep++;
      if (user?.interest?.length > 0) onboardingStep++;

      state.onboardingStep = onboardingStep;
      if (subscription) {
        state.subscription = subscription;
      }
      if (subscriptionLimit) {
        state.subscriptionLimit = subscriptionLimit
      }
    },
    logout(state) {
      state.name = '';
      state.email = '';
      state.onboardingStep = 1;
      state.isEmailVerified = false;
      state.isLinkedinVerified = false;
      state.profilePicture = '';
      state.role = '';
      state.isLoggedIn = false;
      state.accessToken = '';
      state.refreshToken = '';
      state.occupation = '';
      state.interest = '';
      state.subscription = null;
      state.subscriptionLimit = null;
    },
    updateOnboarding(state, action) {
      if (action.payload.occupation !== undefined) {
        state.occupation = action.payload.occupation;
        // Tracking
        setUserProperties({
          persona: action.payload.occupation
        })
      }
      if (action.payload.interest !== undefined) {
        state.interest = action.payload.interest;

        // Tracking
        setUserProperties({
          interests: action.payload.interest
        })
      }

      // Update onboarding step based on new values
      let onboardingStep = 1;
      if (state.occupation) onboardingStep++;
      if (state?.interest?.length > 0) onboardingStep++;

      state.onboardingStep = onboardingStep;
    },
    updateOnLinkedinConnect(state, action) {
      const { user } = action.payload;
      state.name = user.name;
      state.email = user.email;
      state.isLinkedinVerified = user.isLinkedinVerified;
      state.profilePicture = user.profilePicture;

      // Tracking
      setUserProperties({
        linkedinConnected: true
      })
    },
    updateSubscription(state, action) {
      const { subscription, subscriptionLimit } = action.payload;
      if(subscription){
        state.subscription = subscription;
      }
      if(subscriptionLimit){
        state.subscriptionLimit = subscriptionLimit;
      }
    },
    updateCancelSubscription(state) {
      state.subscription.status = 'cancelled'
    },
    updateAiCreditUsage(state) {
      state.subscriptionLimit.aiCreditUsage = state.subscriptionLimit.aiCreditUsage + 1;
    },
    updateSearchUsage(state) {
      state.subscriptionLimit.searchUsage = state.subscriptionLimit.searchUsage + 1;
    }
  },
});

export const {
  login,
  logout,
  updateOnboarding,
  updateOnLinkedinConnect,
  updateSubscription,
  updateCancelSubscription,
  updateAiCreditUsage,
  updateSearchUsage
} = authSlice.actions;

export default authSlice.reducer;
