
import { useNavigate } from "react-router-dom";
import LinkedinIconWithText from "../../asset/svg/LinkedinIconWithText";
import MainLayout from "../../layout/main";
import { fetchKeywords } from "../../services/keywords.service";
import { useQuery } from "@tanstack/react-query";
import LoaderGif from "../../asset/gif/loader.gif";
import { useSelector } from "react-redux";

const TopKeywords = () => {
    const navigate = useNavigate();
    // const authState = useSelector((state) => state.auth);
    // const { subscription } = authState;

    const { data: keywords = [], isLoading, refetch } = useQuery({
        queryKey: ["keywords"],
        queryFn: () => fetchKeywords(),
        staleTime: Infinity,
        retry: false
    });
    return (
        <MainLayout>

            <div className="rounded-2xl mt-2  pt-28 px-4 relative bg-star-light3 min-h-screen">
                <div className="">
                    <span className="flex items-center text-headingOne justify-center gap-4">
                        Top searched  <LinkedinIconWithText /> keywords
                    </span>
                    <p className="text-center text-subHeadingThree mt-4 text-[#8B8699]">Tap on these keywords to get viral posts</p>
                </div>

                <div className="flex justify-center flex-wrap mt-9 gap-y-2 gap-x-2">
                    {
                        !isLoading ?
                            keywords.data.map((item, index) => (
                                <div
                                    key={index} // Add a unique key for each item
                                    className="px-5 py-2 bg-star-light4 text-star-default rounded-xl cursor-pointer hover:bg-star-dark hover:text-star-light4 transition-colors duration-300"
                                    onClick={() => {
                                        navigate(`/`, { state: { keyword: item } });
                                    }}
                                >
                                    {item}
                                </div>
                            ))
                            :
                            <div className="flex flex-col h-[70vh] justify-center items-center">
                                <img src={LoaderGif} width={200} alt="loading" />
                                <p className="text-bodyThreeBold text-star-light">Curating top searched keywords…</p>
                            </div>
                    }
                </div>
            </div>

        </MainLayout>
    )
}

export default TopKeywords;