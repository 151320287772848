import LogoIcon from "../../asset/svg/LogoIcon";

const Header = () => {
    return (
        <div className="flex px-8 py-4 justify-between items-center border-b border-starLight2">
            <a href="http://superpen.io/" className="cursor-pointer">
                <LogoIcon />
            </a>
        </div>
    )
}
export default Header;