import React, { useState } from 'react';

const ToggleBtn = ({ isOn, onChange }) => {

    return (
        <div
            className={`min-w-[42px] h-6 flex items-center  rounded-full p-1 cursor-pointer transition-colors duration-300 ${isOn ? 'bg-star-default' : 'bg-star-light3'
                }`}
            onClick={onChange}
        >
            <div
                className={` w-[18px] h-[18px] rounded-full shadow-md transform transition-transform  duration-300 ${isOn ? 'translate-x-4 bg-hover-gradient-2' : 'translate-x-0 bg-star-light'
                    }`}
            />
        </div>
    );
};

export default ToggleBtn;
