import { ArrowLeft, CheckCircleSolid } from "iconoir-react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import LinkedinAvatarImage from "../../asset/svg/linkedin-avatar.svg";
import LinkedinStrokeImage from "../../asset/svg/linkedin-stroke.svg";
import { EXTERNAL_LINKS } from "../../constent/links";
import { initiateLinkedInAuth } from "../../services/onboardin.service";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const ConnectLinkedin = ({ handleBack, handleNext }) => {
    const authState = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const isAuthenticated = authState.isLoggedIn;
    const isLinkedinVerified = authState.isLinkedinVerified;

    if (isAuthenticated && isLinkedinVerified) {
        return <Navigate to='/' />
    }
    return (
        <div>
            <div className="absolute h-[319px] [zIndex:-1] left-0 right-0 top-0">
                <img className="h-full w-full object-contain" src={LinkedinStrokeImage} alt="onboarding-step-3" />
            </div>
            <div className="flex items-center justify-center gap-2">
                <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-star-light w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                {/* <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" /> */}
            </div>
            <div className="flex justify-center mt-16 [min-height:337px]">
                <div className="text-center">
                    <p className="text-headingTwo bg-hover-gradient-1 bg-clip-text text-transparent">Connect your LinkedIn</p>
                    <p className="mt-1 text-subHeadingThree text-gray-6">Connect LinkedIn to get superb recommendations and analytics</p>
                    <div className="flex items-center justify-center my-5">
                        <img src={LinkedinAvatarImage} alt="avatar" />
                    </div>
                    <div className="flex justify-center">
                        <div className="flex flex-col ">
                            <div className="flex items-center gap-1">
                                <CheckCircleSolid color="#A489F3" />
                                <p className="text-bodyOne text-space-default">Get insights on your post performance</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <CheckCircleSolid color="#A489F3" />
                                <p className="text-bodyOne text-space-default">Receive personalized content recommendations</p>
                            </div>
                            <div className="flex items-center gap-1">
                                <CheckCircleSolid color="#A489F3" />
                                <p className="text-bodyOne text-space-default">Schedule posts for LinkedIn seamlessly</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-8">
                        <button className="filled-btn my-2.5" onClick={() => {
                            trackEvent(MIXPANEL_EVENTS.LINKEDIN_CONNECT_CLICKED);
                            initiateLinkedInAuth();
                        }}>Connect LinkedIn</button>
                    </div>
                </div>
            </div>
            <p className="text-center text-bodyOne text-gray-5 mb-10"> <b>74%</b>  SuperPen users who connect LinkedIn get better recommendations and make an average of 2 viral posts/week</p>
            <div className="flex justify-between items-center ">
                <button className="text-btn" onClick={handleBack}>
                    <ArrowLeft />
                    Back
                </button>
                <div>
                    <p className="text-bodyTwo text-gray-5">Don’t worry, your data is secure with us. <a className="text-star-light" target="_blank" rel="noreferrer" href={EXTERNAL_LINKS.privacyPolicy}>Privacy Policy</a></p>
                </div>
                <div>
                    <button onClick={() => {
                        trackEvent(MIXPANEL_EVENTS.LINKEDIN_SKIP);
                        navigate('/')
                    }} className="text-btn">Skip for now</button>
                </div>
            </div>
        </div>
    )
}
export default ConnectLinkedin;