import api from "../utils/api";
import { getLocationService } from "./ip.service";

export const createBookmark = async ({ label, postId, source }) => {
    try {
        const response = await api.post('/bookmark/create', { label, postId, source });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const storeBookmark = async (data) => {
    try {
        const response = await api.post('/bookmark/store', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const removeBookmark = async (data) => {
    try {
        const response = await api.post('/bookmark/remove', data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const getCollectionsWithPostId = async ({ postId }) => {
    console.log(postId)
    try {
        const response = await api.get(`/bookmark/get-collections/${postId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};


export const getCollections = async () => {
    try {
        const response = await api.get(`/bookmark`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const getbookmarkPosts = async (data) => {
    try {
        console.log(data, "inside service");
        const response = await api.post(`/bookmark/get-bookmarks`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};