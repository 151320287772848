const MiniLogo = () => {
    return (
        <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SP Logomark" clipPath="url(#clip0_597_957)">
                <path id="Vector" d="M7.26022 0C7.04895 -1.73714e-06 6.84093 0.0520597 6.65457 0.15158C6.4682 0.2511 6.30924 0.395012 6.19173 0.570587L0.895817 8.48321C0.754632 8.69417 0.679049 8.94219 0.678591 9.19604C0.678133 9.44988 0.752822 9.69818 0.893245 9.90964L17.923 35.5524C17.9646 35.615 18.0267 35.661 18.0987 35.6826C18.1706 35.7041 18.2479 35.6998 18.317 35.6703C18.386 35.6408 18.4426 35.5881 18.4769 35.5212C18.5112 35.4544 18.5209 35.3777 18.5045 35.3044L13.7634 14.109C13.7252 13.9383 13.7223 13.7616 13.7548 13.5897C13.7872 13.4178 13.8545 13.2544 13.9523 13.1093L20.5586 3.3164C20.6172 3.22957 20.6961 3.15836 20.7885 3.10898C20.8808 3.05959 20.9839 3.03353 21.0886 3.03305C21.1934 3.03257 21.2966 3.05769 21.3895 3.10623C21.4823 3.15476 21.5618 3.22525 21.6212 3.31154L28.299 13.0177C28.3995 13.1638 28.4687 13.3291 28.5023 13.5032C28.5358 13.6773 28.533 13.8565 28.4939 14.0295L25.9574 25.267C25.9409 25.3402 25.9505 25.417 25.9846 25.4839C26.0188 25.5507 26.0753 25.6036 26.1443 25.6332C26.2133 25.6627 26.2905 25.6672 26.3625 25.6459C26.4345 25.6245 26.4967 25.5786 26.5383 25.5161L36.9423 9.91016C37.0833 9.69863 37.1585 9.45004 37.1582 9.19581C37.158 8.94159 37.0824 8.69313 36.941 8.48186L31.646 0.570581C31.5285 0.395013 31.3696 0.251107 31.1832 0.151588C30.9968 0.0520702 30.7888 6.0168e-06 30.5775 0L7.26022 0Z" fill="#7145FD" />
                <path id="Vector_2" d="M20.6668 12.5583L20.9894 11.3601L21.312 12.5583C21.4194 12.9573 21.6298 13.321 21.9219 13.6132C22.2141 13.9054 22.5779 14.1158 22.9769 14.2232L24.1751 14.5458L22.9769 14.8684C22.5779 14.9758 22.2141 15.1861 21.9219 15.4783C21.6298 15.7705 21.4194 16.1343 21.312 16.5333L20.9894 17.7315L20.6668 16.5333C20.5594 16.1343 20.349 15.7705 20.0569 15.4783C19.7647 15.1861 19.4009 14.9758 19.0019 14.8684L17.8037 14.5458L19.0019 14.2232C19.4009 14.1158 19.7647 13.9054 20.0569 13.6132C20.349 13.3211 20.5594 12.9573 20.6668 12.5583Z" fill="#7145FD" />
            </g>
            <defs>
                <clipPath id="clip0_597_957">
                    <rect width="36.6429" height="36" fill="white" transform="translate(0.678589)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default MiniLogo;