const HeartAndThumbIcon = () => {
    return (
        <svg width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.5" cy="11.5" r="11.5" fill="#FD6483" />
            <path d="M8.36183 5.9212C8.915 5.82695 9.48226 5.85804 10.0218 6.01218C10.5614 6.16632 11.0594 6.43958 11.4793 6.81182L11.5025 6.83245L11.5237 6.8137C11.9245 6.46201 12.3956 6.19982 12.9057 6.04462C13.4158 5.88943 13.9531 5.84479 14.4818 5.9137L14.6356 5.9362C15.302 6.05126 15.9249 6.34442 16.4384 6.78462C16.9518 7.22481 17.3366 7.79566 17.5521 8.43672C17.7676 9.07777 17.8057 9.76517 17.6624 10.4261C17.5191 11.0871 17.1997 11.6969 16.7381 12.1912L16.6256 12.3068L16.5956 12.3324L11.9393 16.9443C11.8319 17.0507 11.6895 17.1145 11.5386 17.1239C11.3877 17.1333 11.2386 17.0877 11.1187 16.9956L11.06 16.9443L6.37683 12.3056C5.88072 11.8229 5.52789 11.2122 5.35743 10.5414C5.18697 9.87048 5.20552 9.16551 5.41103 8.50452C5.61654 7.84353 6.00099 7.25232 6.52181 6.79637C7.04262 6.34041 7.67947 6.03751 8.36183 5.9212Z" fill="#F7F6FF" />
            <circle cx="26.5" cy="11.5" r="11.5" fill="#3091E2" />
            <g clipPath="url(#clip0_347_9843)">
                <path d="M20.6741 10.0938H22.9844V16.1875H20.6741C20.6031 16.1875 20.5349 16.1381 20.4847 16.0502C20.4345 15.9623 20.4062 15.8431 20.4062 15.7188V10.5625C20.4062 10.4382 20.4345 10.319 20.4847 10.231C20.5349 10.1431 20.6031 10.0938 20.6741 10.0938Z" fill="#F7F6FF" />
                <path d="M23.6875 10.0938L26.0312 5.40625C26.5285 5.40625 27.0054 5.60379 27.3571 5.95542C27.7087 6.30706 27.9062 6.78397 27.9062 7.28125V8.6875H31.6562C31.7892 8.68754 31.9207 8.71587 32.0419 8.77061C32.1631 8.82535 32.2712 8.90525 32.3592 9.005C32.4471 9.10475 32.5129 9.22207 32.552 9.34917C32.5911 9.47627 32.6028 9.61024 32.5861 9.74219L31.883 15.3672C31.8545 15.5937 31.7443 15.802 31.5731 15.953C31.4019 16.1041 31.1814 16.1874 30.9531 16.1875H23.6875" fill="#F7F6FF" />
            </g>
            <defs>
                <clipPath id="clip0_347_9843">
                    <rect width="15" height="15" fill="white" transform="translate(19 4)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default HeartAndThumbIcon;