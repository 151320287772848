import { useState, useEffect } from 'react';

const useScrolledDown = (height = 0) => {
    const [scrolledDown, setScrolledDown] = useState(false);
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0); // Track the last scroll position

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Check if user scrolled past the specified height
            setScrolledDown(currentScrollY > height);

            // Determine scroll direction (down if current scroll is greater than last scroll)
            setIsScrollingDown(currentScrollY > lastScrollY);

            // Update the last scroll position
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [height, lastScrollY]);

    return { scrolledDown, isScrollingDown }; // Return both scrolled state and direction
};

export default useScrolledDown;
