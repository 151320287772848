import { useState } from "react";
import InputField from "../../component/custom-form/InputField";
import { useDispatch, useSelector } from "react-redux";
import { Xmark } from "iconoir-react";
import { unsubscribePlan } from "../../services/payment.service";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { logout, updateCancelSubscription } from "../../redux/slice/auth.slice";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const BackgroundLine = () => {
    return (
        <svg width="60" height="166" viewBox="0 0 60 166" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-223.6 164.77C-226.327 166.109 -229.623 164.985 -230.962 162.259C-232.302 159.533 -231.177 156.237 -228.451 154.897L-223.6 164.77ZM-158.339 -42.8254L-11.3371 -69.581L-9.36731 -58.7588L-156.37 -32.0032L-158.339 -42.8254ZM25.5543 42.3602L-223.6 164.77L-228.451 154.897L20.7038 32.4874L25.5543 42.3602ZM-11.3371 -69.581C56.6017 -81.9464 87.5331 11.91 25.5543 42.3602L20.7038 32.4874C71.2243 7.66667 46.0114 -68.8381 -9.36731 -58.7588L-11.3371 -69.581Z" fill="url(#paint0_linear_458_3427)" />
            <defs>
                <linearGradient id="paint0_linear_458_3427" x1="-471.074" y1="346.752" x2="184.499" y2="-253.112" gradientUnits="userSpaceOnUse">
                    <stop offset="0.288815" stopColor="#FBDEBC" />
                    <stop offset="0.427773" stopColor="#FFC0FA" />
                    <stop offset="0.567652" stopColor="#C5BBFF" />
                    <stop offset="0.75671" stopColor="#FD6483" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const CancelSubscription = ({ onClose }) => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const dispatch=useDispatch();
    const [text, setText] = useState('');

    const handleChange = (event) => {
        setText(event.target.value);
    }
    const { mutate, isPending } = useMutation({
        mutationFn: unsubscribePlan,
        onSuccess: () => {
            toast.success('Subscription cancelled successfully.');
            trackEvent(MIXPANEL_EVENTS.CANCEL_REQUEST);
            dispatch(updateCancelSubscription());
            onClose();
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong !!!')
        }
    });

    const handleUnSubscribe = (event) => {
        event.preventDefault();
        mutate({ subscriptionId: subscription.subscriptionId, cancelReason: text });
    }
    return (
        <div className="w-full h-full flex items-center justify-center top-0 left-0 fixed z-[99]">
            <div className="absolute -z-10 b top-0 backdrop-blur-md left-0 w-full h-full" style={{
                background: "rgba(106, 101, 167, 0.52)"
            }} />
            <div className="z-0 w-[561px] relative p-8 rounded-[25px] overflow-hidden bg-gray-moon" style={{
                boxShadow: "0px 14px 32px 0px rgba(39, 39, 47, 0.06)",
            }}>
                <div className="absolute top-0 -left-1 ">
                    <BackgroundLine />
                </div>
                <div className="flex justify-between items-center mt-1">
                    <p className="text-subHeadingThreeBold text-venus-default">We’re sorry to see you go...</p>
                    <div className="cursor-pointer" onClick={onClose}>
                        <Xmark width={24} height={24} color="#8B8699" />
                    </div>
                </div>
                <form onSubmit={handleUnSubscribe}>
                    <div className="mt-8">
                        <p className="text-bodyTwo mb-2 text-space-default">Share your feedback or comments <span className="text-venus-default">*</span></p>
                        <InputField required={true} value={text} onChange={handleChange} maxLength={150} />
                    </div>
                    <div className="mt-8 flex items-center justify-center gap-2">
                        <button className="filled-btn light-red">Cancel subscription</button>
                        <button type="button" disabled={isPending} className="filled-btn red" onClick={onClose}>Stay subscribed</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CancelSubscription;