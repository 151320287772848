import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { ArrowLeft } from "iconoir-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import StrokeBackgroundImage from '../../asset/svg/auth-stroke.svg';
import ResetPasswordImage from '../../asset/svg/reset.svg';
import PasswordInputField from "../../component/form/PasswordInputField";
import Header from "../../layout/auth/Header";
import { resetPassword } from "../../services/auth.service";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import useResponsive from "../../hooks/useResponsive";
import MiniLogo from "../../asset/svg/MiniLogo";

const trackLoginClick = (type) => {
    trackEvent(MIXPANEL_EVENTS.LOGIN_CLICKED, {
        "Log in click source": type
    })
}

const SuccessScreen = () => {
    const { isMobile } = useResponsive();


    return (
        isMobile ? (
            <div className="bg-star-light4 border min-h-dvh">
                <div className="flex items-center flex-col px-5 justify-center text-center mt-5">
                    <div className="py-2">
                        <MiniLogo />
                    </div>
                    <p className="text-headingThree text-center text-space-default">Password reset complete!</p>
                    <div className="flex justify-center">
                        <img src={ResetPasswordImage} alt="reset-image" />
                    </div>
                </div>
                <div className="fixed justify-center flex z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >
                    <Link to={'/login?utm_source=reset-password-success'} className="filled-btn small " onClick={() => { trackLoginClick('reset-password-success') }}>
                        Go to log in
                    </Link>
                </div>
            </div>
        ) : (

            <div>
                <p className="text-headingTwo text-center text-space-default">Password reset complete!</p>
                <div className="flex justify-center">
                    <img src={ResetPasswordImage} alt="reset-image" />
                </div>
                {/* <p className="text-bodyOne text-center text-gray-6 mt-4">Your password is successfully reset. </p> */}
                <div className="flex items-center justify-center">
                    <Link to={'/login?utm_source=reset-password-success'} className="filled-btn mt-4" onClick={() => { trackLoginClick('reset-password-success') }}>
                        Go to log in
                    </Link>
                </div>
            </div>
        )
    )
}

const resetPasswordSchema = z.object({
    password: z.string().min(4, "Password must be at least 4 characters"),
    confirmPassword: z.string().min(4, "Password must be at least 4 characters"),
    userId: z.string(),
    token: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
    message: 'Oops! The passwords don’t match.',
    path: ['confirmPassword']
})

const ResetPassword = () => {
    const { isMobile } = useResponsive();
    const [isReset, setIsReset] = useState(false);
    let { userId, token } = useParams();

    const { handleSubmit, control } = useForm({
        resolver: zodResolver(resetPasswordSchema),
        defaultValues: {
            password: '',
            confirmPassword: '',
            userId,
            token
        }
    });

    const { mutate, isPending } = useMutation({
        mutationFn: resetPassword,
        onSuccess: () => {
            trackEvent(MIXPANEL_EVENTS.RESET_PW_SUCCESS);
            setIsReset(true);
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong')
            trackEvent(MIXPANEL_EVENTS.RESET_PW_FAILURE, {
                "Reason (password reset failure)": error?.message || 'Server Error'
            });
        }
    });

    const onSubmit = (data) => {
        trackEvent(MIXPANEL_EVENTS.SET_PW_CLICKED);
        mutate(data);
    };

    return (
        isMobile ? (
            <MobileView
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isPending={isPending}
                control={control}
                isReset={isReset}
            />
        ) : (

            <div className="bg-star-light4 h-screen overflow-hidden">
                <Header />
                <div className="flex relative items-center justify-center h-5/6">
                    <div className="py-6 px-9 rounded-3xl border border-gray-3 bg-gray-moon [width:449px] z-10">
                        {
                            isReset ? <SuccessScreen /> : (
                                <div>
                                    <p className="text-headingTwo text-space-default">Reset Password!</p>
                                    <p className="text-bodyOne bg-heading-gradient-3 bg-clip-text text-transparent">Choose a strong password that’s easy to remember.</p>

                                    <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                                        <div >
                                            <p className="text-bodyTwo text-space-default mb-1">New Password</p>
                                            <PasswordInputField
                                                name="password"
                                                control={control}
                                                placeholder="4+ characters"
                                            />
                                        </div>
                                        <div className="mt-6">
                                            <p className="text-bodyTwo text-space-default mb-1">Confirm New Password</p>
                                            <PasswordInputField
                                                name="confirmPassword"
                                                control={control}
                                                placeholder="4+ characters"
                                            />
                                        </div>

                                        <div className="flex justify-between mt-6">
                                            <Link to={'/login?utm_source=reset-password-request'} className="text-btn" onClick={() => { trackLoginClick('reset-password-request') }}>
                                                <ArrowLeft />
                                                Back to log in
                                            </Link>
                                            <button className="filled-btn" disabled={isPending}>Set New Password</button>
                                        </div>
                                    </form>
                                </div>
                            )
                        }

                    </div>
                    <div className="h-full w-full absolute ">
                        <img src={StrokeBackgroundImage} alt="background" className="w-full" />
                    </div>
                </div>
            </div>
        )
    );
};

export default ResetPassword;


const MobileView = ({ handleSubmit, onSubmit, control, isPending, isReset }) => {
    return (
        isReset ? <SuccessScreen /> : (
            <div className="bg-star-light4 border min-h-dvh">
                <div className="flex items-center flex-col justify-center text-center mt-5">
                    <div className="py-2">
                        <MiniLogo />
                    </div>
                    <p className="text-headingThree text-space-default">Reset Password</p>
                    <p className="text-bodyOne bg-clip-text text-transparent bg-heading-gradient-3 w-[90%]">Choose a strong password that’s easy to remember.</p>

                </div>
                <form className="mt-8 px-5" onSubmit={handleSubmit(onSubmit)}>
                    <div >
                        <p className="text-bodyTwo text-space-default mb-1">New Password</p>
                        <PasswordInputField
                            name="password"
                            control={control}
                            placeholder="4+ characters"
                        />
                    </div>
                    <div className="mt-6">
                        <p className="text-bodyTwo text-space-default mb-1">Confirm New Password</p>
                        <PasswordInputField
                            name="confirmPassword"
                            control={control}
                            placeholder="4+ characters"
                        />
                    </div>

                    <div className="fixed justify-between flex z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >

                        <Link to={'/login?utm_source=reset-password-request'} className="text-btn small" onClick={() => { trackLoginClick('reset-password-request') }}>
                            <ArrowLeft />
                            Back to log in
                        </Link>
                        <button className="filled-btn small" disabled={isPending}>Set New Password</button>
                    </div>
                </form>

                <div className="fixed bottom-0 w-full right-0 ">
                    <MobileViewLine />
                </div>
            </div>
        )
    )
}
const MobileViewLine = () => {
    return (
        <svg width="360" height="182" viewBox="0 0 360 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6723 456.422L-38.066 168.296C-53.4112 90.3702 17.7742 23.093 94.7099 42.8095L292.258 93.4355C338.142 105.194 358.248 159.089 331.277 198.027L78.479 562.988" stroke="url(#paint0_linear_597_1118)" strokeWidth="16" />
            <defs>
                <linearGradient id="paint0_linear_597_1118" x1="356.45" y1="152.109" x2="14.0248" y2="-46.2313" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DCD1FF" />
                    <stop offset="0.341459" stopColor="#FFE5FD" />
                    <stop offset="1" stopColor="#F7AFF1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
