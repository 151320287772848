const ThumbsUpIcon = ({ firstColor = '#3091E2', secondColor = '#3091E2' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="url(#paint0_linear_478_29089)" />
            <g clipPath="url(#clip0_478_29089)">
                <path d="M4.93403 8.7771H6.94296V14.076H4.93403C4.87226 14.076 4.81301 14.0331 4.76933 13.9566C4.72565 13.8802 4.70111 13.7765 4.70111 13.6684V9.18471C4.70111 9.0766 4.72565 8.97293 4.76933 8.89649C4.81301 8.82004 4.87226 8.7771 4.93403 8.7771Z" fill="#F7F6FF" />
                <path d="M7.55432 8.77726L9.59236 4.70117C10.0248 4.70117 10.4395 4.87295 10.7453 5.17872C11.051 5.48448 11.2228 5.89919 11.2228 6.33161V7.55443H14.4837C14.5993 7.55447 14.7136 7.5791 14.819 7.6267C14.9244 7.6743 15.0185 7.74378 15.0949 7.83052C15.1714 7.91726 15.2286 8.01928 15.2626 8.1298C15.2966 8.24032 15.3067 8.35682 15.2923 8.47155L14.6808 13.3629C14.656 13.5598 14.5602 13.741 14.4113 13.8723C14.2625 14.0036 14.0708 14.0761 13.8723 14.0762H7.55432" fill="#F7F6FF" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_478_29089" x1="0" y1="20" x2="24.0816" y2="11.3073" gradientUnits="userSpaceOnUse">
                    <stop stopColor={firstColor} />
                    <stop offset="1" stopColor={secondColor} />
                </linearGradient>
                <clipPath id="clip0_478_29089">
                    <rect width="13.0435" height="13.0435" fill="white" transform="translate(3.47827 3.47827)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ThumbsUpIcon;