import { combineReducers } from 'redux';
import authSlice from './slice/auth.slice';
import postSlice from './slice/post.slice';

const rootReducer = combineReducers({
  auth: authSlice,
  post: postSlice
});

export default rootReducer;
