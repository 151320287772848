import { createSlice } from '@reduxjs/toolkit';

const postsSlice = createSlice({
    name: 'posts',
    initialState: {
        postIds: [], // Array to store postIds
    },
    reducers: {
        addPostId: (state, action) => {
            if (!state.postIds.includes(action.payload)) {
                state.postIds = [...state.postIds, action.payload];
            }
        },
        setPostIds: (state, action) => {
            state.postIds = action.payload;
        },
        removePostId: (state, action) => {
            state.postIds = state.postIds.filter(id => id !== action.payload);
            //   state.postIds = [ ...state.postIds, action.payload];
        },
        clearPostIds: state => {
            state.postIds = [];
        },
    },
});

export const { addPostId, setPostIds, removePostId, clearPostIds } = postsSlice.actions;

export default postsSlice.reducer;
