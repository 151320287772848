import { useState, useEffect, useCallback } from 'react';

// Custom debounce function
const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const useResponsive = () => {
    // State to track screen size
    const [state, setState] = useState({
        isMobile: false,
        isTablet: false,
        isDesktop: false,
    });

    // Update the state based on the window size
    const onResizeHandler = useCallback(() => {
        const isMobile = window.innerWidth <= 768;
        const isTablet = window.innerWidth > 768 && window.innerWidth <= 990;
        const isDesktop = window.innerWidth > 990;

        setState({ isMobile, isTablet, isDesktop });
    }, []);

    // Debounced resize handler
    const debouncedCall = useCallback(debounce(onResizeHandler, 500), [onResizeHandler]);

    useEffect(() => {
        // Initial load
        onResizeHandler();

        // Setup the event listener
        window.addEventListener('resize', debouncedCall);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', debouncedCall);
        };
    }, [debouncedCall, onResizeHandler]);

    return state;
};

export default useResponsive;
