export const isObjectEmpty = (obj) => {
  if (typeof obj !== 'object') {
    return false;
  }
  if (obj === null) {
    return true;
  }
  if (Object.keys(obj).length > 0) {
    return false;
  }
  return true;
}

export function nearestRoundOff(number) {
  if (number < 10) {
    return number;
  }
  const lastDigit = number % 10;

  return number - lastDigit;
}
