import api from "../utils/api";

export const saveOnBoarding = async ({ occupation, interest }) => {
    try {
        const response = await api.post('/auth/onboarding', { occupation, interest });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const initiateLinkedInAuth = async () => {
    try {
        const response = await api.get('/auth/linkedin');
        window.location.href = response.data.authUrl; // Redirect to LinkedIn OAuth URL
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const verifyLinkedin = async (code) => {
    try {
        const response = await api.get(`/auth/linkedin/verify?code=${code}`);
        return response.data;
    } catch (error) {
        console.error(error.message);
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};