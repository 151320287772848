const LinkedinSend = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <g clipPath="url(#clip0_568_16304)">
                <path d="M21 3.5L0 10.5L7.66 14.76L16 8.5L9.74 16.84L14 24.5L21 3.5Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_568_16304">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default LinkedinSend;