import api from "../utils/api";

export const generatePost = async ({ content, comments, tag }) => {
    try {
        const response = await api.post('/genai/generate-post', { content, comments, tag });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};

export const generateTemplate = async ({ content, editorText, tag,templateType,templateExample}) => {
    try {
        const response = await api.post('/genai/generate-template', { content, editorText, tag,templateType,templateExample });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};