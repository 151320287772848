const FlameIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#FF9EB7" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.265 15.8143C6.82535 15.3132 5.76172 14.0962 5.76172 12.3309C5.76172 9.79514 6.98152 7.48803 8.22654 5.13322C8.36681 4.86792 8.5074 4.60201 8.6466 4.3351C8.70186 4.22915 8.93955 4.30471 8.93042 4.42386C8.73723 6.94691 9.4612 11.6803 12.3848 8.04406C12.4295 7.98847 12.5215 7.99167 12.556 8.05409C14.6761 11.8867 13.9898 14.7048 11.7789 15.689C11.8327 15.515 11.8617 15.3301 11.8617 15.1385C11.8617 14.1103 11.0282 13.2769 10.0001 13.2769C8.97197 13.2769 8.13852 14.1103 8.13852 15.1385C8.13852 15.3769 8.18333 15.6048 8.265 15.8143ZM10.8617 15.1385C10.8617 15.6143 10.4759 16 10.0001 16C9.52426 16 9.13852 15.6143 9.13852 15.1385C9.13852 14.6626 9.52426 14.2769 10.0001 14.2769C10.4759 14.2769 10.8617 14.6626 10.8617 15.1385Z" fill="#F7F6FF" />
        </svg>

    )

}
export default FlameIcon;