import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Banner = () => {
    const authState = useSelector((state) => state.auth);
    const subscription = authState.subscription;
    const subscriptionLimit = authState.subscriptionLimit;
    const endDate = subscription.endDate;
    const now = moment(); // Current date

    const diffInDays = moment(endDate).diff(now, 'days', true);

    // console.log(diffInDays)
    let text = '';

    if (diffInDays <= 0) {
        text = 'Your FREE Trial plan is expired !!!'
    } else {
        text = `Your FREE Trial plan is expiring in ${Math.floor(diffInDays) + 1} day(s)!`
    }

    if (!subscription.isFreeTrial) {
        return <></>
    }

    const remaining = (subscriptionLimit.searchLimit - subscriptionLimit.searchUsage);
    const remainingText = remaining === subscriptionLimit.searchLimit ? remaining - 1 : remaining;
    return (
        <>
            <div className="h-[44px]" />
            <div className="fixed top-0 left-0 z-[11] w-full bg-star-light4">
                <div className="flex items-center justify-center py-2 px-2.5 text-center" style={{
                    // borderColor: '#D9974A',
                    background: '#120B2A'
                }}>
                    <p className="text-bodyTwoBold" style={{ color: '#F7F6FF' }}>To upgrade and get unlimited viral posts | </p>
                    <Link to={'/payment-pending'} className="ml-2 cursor-pointer text-bodyTwoBold text-btn-dark" style={{ padding:'4px 8px' }}><p>Click here</p></Link>
                </div>
            </div>
        </>
    )
}

export default Banner;