const PenNibIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10.605" r="10" fill="#FFA79E" />
            <g clipPath="url(#clip0_87_3443)">
                <path fillRule="evenodd" clipRule="evenodd" d="M4.94287 15.2126L7.85232 12.3028C7.74294 12.1009 7.68082 11.8697 7.68082 11.624C7.68082 10.8361 8.31955 10.1974 9.10745 10.1974C9.89536 10.1974 10.5341 10.8361 10.5341 11.624C10.5341 12.4119 9.89536 13.0506 9.10745 13.0506C8.86178 13.0506 8.63061 12.9885 8.42878 12.8792L5.51984 15.7885L11.9328 14.7198C12.0033 14.708 12.0694 14.6779 12.1246 14.6325C12.1798 14.587 12.2221 14.5279 12.2472 14.461L13.3874 11.4202L9.31136 7.34412L6.2706 8.4844C6.20368 8.50951 6.14454 8.55177 6.09912 8.60695C6.05369 8.66212 6.02356 8.72828 6.01177 8.79877L4.94287 15.2126ZM9.10745 11.0126C8.76978 11.0126 8.49604 11.2863 8.49604 11.624C8.49604 11.7896 8.56186 11.9398 8.66876 12.0499L8.67523 12.0562L8.68158 12.0627C8.79168 12.1696 8.94188 12.2354 9.10745 12.2354C9.44513 12.2354 9.71886 11.9617 9.71886 11.624C9.71886 11.2863 9.44513 11.0126 9.10745 11.0126Z" fill="white" />
                <path d="M14.0584 10.7985L15.5696 9.28727C15.646 9.21083 15.6889 9.1072 15.6889 8.99914C15.6889 8.89108 15.646 8.78745 15.5696 8.71101L12.1559 5.33047C12.0795 5.25409 11.9758 5.21118 11.8678 5.21118C11.7597 5.21118 11.6561 5.25409 11.5796 5.33047L10.0669 6.84168" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_87_3443">
                    <rect width="13.0435" height="13.0435" fill="white" transform="translate(3.77979 4.08325)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PenNibIcon;