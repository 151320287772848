import { ArrowRight } from "iconoir-react";
import OnBoardingStep1Image from "../../asset/image/onboardingStep1.png";
import { useEffect, useState } from "react";
import InputField from "../../component/custom-form/InputField";
import { trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const RoleForm = ({ onChange, value, handleNext }) => {
    const options = ['Founder', 'Student', 'Creator', 'Working professional', 'Freelancer'];
    const [enableOther, setEnableOther] = useState(false);

    const maxLength = 60;

    const handleClick = (value) => {
        setEnableOther(false);
        onChange('occupation', value)
    }

    const handleOtherClick = () => {
        onChange('occupation', '');
        setEnableOther(true);
    }

    const handleChange = (event) => {
        onChange(event.target.name, event.target.value);
    }
    useEffect(()=>{
        trackPageView(MIXPANEL_EVENTS.PERSONA_VIEW);
    },[])
    return (
        <div >
            <div className="absolute [zIndex:-1] h-full left-0 top-0">
                <img className="h-full w-full object-contain" src={OnBoardingStep1Image} alt="onboarding-step-1" />
            </div>
            <div className="flex items-center justify-center gap-2">
                <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
            </div>
            <div className="flex mt-16 [min-height:337px]">
                <div className="flex-1"></div>
                <div className="flex-1 flex flex-col">
                    <p className="text-headingTwo bg-hover-gradient-1 bg-clip-text text-transparent">Let’s get to know you better!</p>
                    <p className="mt-1 text-subHeadingThree text-gray-6">I am a _________________ </p>
                    <div className="flex flex-wrap items-center gap-2.5 mt-8">
                        {
                            options.map((option) => (
                                <button key={option} className={`outlined-btn ${option === value ? 'active' : ''}`} onClick={() => { handleClick(option) }}>
                                    {option}
                                </button>
                            ))
                        }
                        <button className={`outlined-btn ${enableOther ? 'active' : ''}`} onClick={handleOtherClick}>
                            Other
                        </button>
                    </div>
                    {
                        enableOther && (
                            <div className="mt-4 mb-1">
                                <InputField name='occupation' maxLength={maxLength} value={value} onChange={handleChange} />
                            </div>
                        )
                    }
                    <div className="mt-auto">
                        <p className="text-bodyTwo text-gray-5">
                        Select your primary occupation or role to get <span className="text-bodyTwoBold text-star-default">  personalized content </span> recommendations.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-end mt-20">
                <button className="icon-btn" disabled={!value} onClick={() => handleNext()}>
                    <ArrowRight />
                </button>
            </div>
        </div>
    )
}
export default RoleForm;