// Hook validator ensures the first paragraph is less than or equal to 200 characters.
export const hookValidator = (content) => {
    const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '');
    if (paragraphs.length > 0 && paragraphs[0].length > 200) {
        return false;
    }
    return true;
};

// Spacing validator ensures no paragraph exceeds 250 characters.
export const spacingValidator = (content) => {
    const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '');

    for (const paragraph of paragraphs) {
        if (paragraph.length > 250) {
            return false;
        }
    }
    return true;
};

// Hashtag validator ensures there are no more than 4 hashtags in total.
export const hashtagValidator = (content) => {
    const hashtagRegex = /#[a-z0-9_]+/gi;
    const totalHashtags = (content.match(hashtagRegex) || []).length;

    if (totalHashtags > 4) {
        return false;
    }
    return true;
};

// Emoji validator ensures there are no more than 4 emojis in total.
export const emojiValidator = (content) => {
    const emojiRegex = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;
    const totalEmojis = (content.match(emojiRegex) || []).length;

    if (totalEmojis > 4) {
        return false;
    }
    return true;
};

// Content length validator ensures there are no more than 400 words in total.
export const contentLengthValidator = (content) => {
    const words = content.split(/\s+/).filter(word => word.trim() !== '');

    if (words.length > 400) {
        return false;
    }
    return true;
};

// Formatting strategy ensures that no more than 20% of the text is bold, italic, or underlined.
export const formattingValidator = (content, contentState) => {
    let totalBoldLength = 0;
    let totalItalicLength = 0;
    let totalUnderlineLength = 0;
    const totalLength = content.length;

    // Assuming contentState contains the style information for bold, italic, underline.
    contentState.getBlockMap().forEach(contentBlock => {
        contentBlock.getCharacterList().forEach(char => {
            const styles = char.getStyle();

            if (styles.has('BOLD')) totalBoldLength++;
            if (styles.has('ITALIC')) totalItalicLength++;
            if (styles.has('UNDERLINE')) totalUnderlineLength++;
        });
    });

    const boldPercentage = (totalBoldLength / totalLength) * 100;
    const italicPercentage = (totalItalicLength / totalLength) * 100;
    const underlinePercentage = (totalUnderlineLength / totalLength) * 100;

    if (boldPercentage > 20 || italicPercentage > 20 || underlinePercentage > 20) {
        return false;
    }
    return true;
};
