import DataTable from 'react-data-table-component';

const CustomTable = ({ data, columns, totalRows, handlePerRowsChange, handlePageChange, }) => {

    return (
        <div className='custom-table'>
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange} />
        </div>
    )

};

export default CustomTable;