const RepeatIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <g clipPath="url(#clip0_21_7912)">
                <path d="M15.625 7.76978L17.5 5.89478L15.625 4.01978" stroke="#A489F3" strokeWidth="1.38889" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.5 10.8948C2.5 9.56869 3.02678 8.29692 3.96447 7.35924C4.90215 6.42156 6.17392 5.89478 7.5 5.89478H17.5" stroke="#A489F3" strokeWidth="1.38889" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.375 14.0198L2.5 15.8948L4.375 17.7698" stroke="#A489F3" strokeWidth="1.38889" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.5 10.8948C17.5 12.2209 16.9732 13.4926 16.0355 14.4303C15.0979 15.368 13.8261 15.8948 12.5 15.8948H2.5" stroke="#A489F3" strokeWidth="1.38889" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_21_7912">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.894775)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RepeatIcon;