import { Link } from 'react-router-dom';
import LinkedinPost from '../asset/svg/linkedinPost.svg';
import DialogBox from './DialogBox';
import { useSelector } from 'react-redux';
import { Xmark, ArrowLeft } from 'iconoir-react';
import { useEffect } from 'react';

const LinkedinPostDialog = ({ onClose, onPost }) => {
    const auth = useSelector((state) => state.auth);
    const name = auth.fullName;
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);
    return (
        <DialogBox>
            <div className="relative p-8 w-[680px] bg-white rounded-[25px] overflow-hidden">
                <div className="top-0 left-0 absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="159" height="68" viewBox="0 0 159 68" fill="none">
                        <path d="M153.581 -133.928L103.051 11.9466C90.1975 49.0542 50.8954 69.9636 12.9393 59.8877L-134.422 20.7687" stroke="url(#paint0_linear_85_2492)" stroke-width="11" />
                        <defs>
                            <linearGradient id="paint0_linear_85_2492" x1="-85.3823" y1="19.9182" x2="127.245" y2="98.7759" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#8773FF" />
                                <stop offset="0.505" stop-color="#FFC0FA" />
                                <stop offset="1" stop-color="#FBDEBC" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>


                <div className="text-center flex flex-col items-center justify-center mt-12">
                    <div>
                        <p className="flex items-center justify-center text-headingThree bg-clip-text text-transparent bg-hover-gradient-1">
                            Let’s post on Linkedin now
                        </p>
                        <p className="flex items-center justify-center text-bodyOne text-gray-6">
                            Post will be live instantly on LinkedIn
                        </p>
                    </div>
                </div>

                <div className="text-center flex flex-col items-center justify-center mt-14">
                    <div>
                        <img src={LinkedinPost} alt="expire" />
                    </div>
                </div>
                <div className='text-center flex  items-center justify-between mt-6'>
                    <button className="text-btn mt-8" onClick={onClose}>
                        <ArrowLeft />
                        cancel
                    </button>
                    <button className="filled-btn mt-8 w-36 h-11" onClick={onPost}>
                        confirm
                    </button>
                </div>
            </div>
        </DialogBox>
    )
}
export default LinkedinPostDialog;