import { useState, useEffect } from "react";
import PlanCard from "../../component/PlanCard";
import SegmentedBtn from "../../component/SegmentedBtn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPlans, loadScript, paymentVerified, subscribePlan } from "../../services/payment.service";
import PaymentSuccessModel from "./PaymentSucessModel";
import LoaderGif from "../../asset/gif/loader.gif";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateSubscription } from "../../redux/slice/auth.slice";
import { RAZORPAY_KEY } from "../../utils/config";
import { Link, Navigate } from "react-router-dom";
import moment from "moment";
import { trackEvent, trackPageView } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import CustomPlan from "../../component/CustomPlan";
import { Xmark } from "iconoir-react";
import FreePlanCard from "../../component/FreePlanCard";
const BackgroundSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="708" viewBox="0 0 400 708" fill="none">
            <path opacity="0.67" d="M122.969 -68.0932L321.308 38.0632C385.329 72.3283 404.486 155.156 362.013 214.052L12.4321 698.81" stroke="url(#paint0_linear_52_5067)" strokeWidth="30" />
            <defs>
                <linearGradient id="paint0_linear_52_5067" x1="590.016" y1="465.409" x2="-87.54" y2="103.457" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0693513" stopColor="#5F45FD" />
                    <stop offset="0.33" stopColor="#FDA1F5" />
                    <stop offset="0.776542" stopColor="#FBDEBC" />
                    <stop offset="1" stopColor="#3091E2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const PaymentPending = () => {
    const authState = useSelector((state) => state.auth);
    // const subscription = authState.subscription;

    // const tenure = ['Monthly', 'Yearly'];
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        trackPageView(MIXPANEL_EVENTS.PAYMENT_VIEWED);
    }, [])

    const { data, isLoading } = useQuery({
        queryKey: ['plans'],
        queryFn: () => fetchPlans(),
        staleTime: Infinity,
    });

    // Extract monthly and yearly plans from fetched data
    const monthlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'monthly')?.sort((a, b) => a.amount - b.amount) || [];
    const yearlyPlan = data?.data?.plans.filter((item) => item.billingPeriod === 'yearly')?.sort((a, b) => a.amount - b.amount) || [];


    // useEffect(() => {
    //     if (yearlyPlan.length > 0) {
    //         setActiveIndex(1);
    //     }
    // }, [data]);

    const handleChange = (index) => {
        trackEvent(MIXPANEL_EVENTS.PLAN_TOGGLED, {
            toggledTo: index === 0 ? 'yearly' : 'monthly'
        })
        // if (index === 0 && monthlyPlan.length > 0) {
        //     setCurrentPlan(monthlyPlan[0]);
        // } else if (yearlyPlan.length > 0) {
        //     setCurrentPlan(yearlyPlan[0]);
        // }
        setActiveIndex(index);
    };

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId) {
                toast.success(data.message);
                return;
            }

            const options = {
                key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                subscription_id: data.data.subscriptionId,
                name: 'SuperPen',
                description: 'Subscription Payment',
                handler: async (response) => {
                    try {
                        const result = await paymentVerified(response);
                        setResponse(result.data)
                        dispatch(updateSubscription(result.data))
                        setOpenSuccess(true);
                    } catch (error) {
                        console.log(error);
                        toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
                    }
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment Model Closed');
                    },
                },
                theme: {
                    color: '#7145FD',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handlePayment = (planId) => {
        if (planId) {
            mutate({
                planId
            });
        }
    };

    // if (subscription && subscription.endDate) {
    //     // const today = moment(); // Get today's date
    //     // const endDate = moment(subscription.endDate);

    //     // const daysLeft = endDate.diff(today, 'days');

    //     // if (daysLeft >= 0) {
    //         return <Navigate to='/' />;
    //     // }
    // }
    const currencySymbol = monthlyPlan[0]?.currency === 'INR' ? '₹' : '$';
    const currentPlans = activeIndex ? monthlyPlan : yearlyPlan;
    const currentAmount = response?.subscription?.amountPaid;
    return (
        <>
            {openSuccess && (
                <PaymentSuccessModel
                    amount={`${currencySymbol}${currentAmount}`}
                // onClose={() => {
                //     setOpenSuccess(false);
                // }}
                />
            )}

            <div className="min-h-screen relative bg-star-light4 pt-10 pb-10 ">
                <div className="absolute top-0 left-0 z-0">
                    <BackgroundSVG />
                </div>
                <Link to={'/'} className="absolute top-[20px] right-[20px] small cursor-pointer icon-outline-btn z-10">
                    <Xmark />
                </Link>
                <div className="container mx-auto  relative z-10">
                    <div className="">
                        <p className="text-headingThree md:text-headingTwo text-center"><span className="text-star-default">Save 30% </span>for 12 months</p>
                        <p className="text-bodyOne md:text-subHeadingThree text-gray-5 text-center">Limited Period Offer</p>
                        <div className="flex justify-center mt-4 mb-20 ">
                            <SegmentedBtn list={['Yearly (Save 30%)', 'Monthly']} activeIndex={activeIndex} onChange={handleChange} />
                        </div>
                        <div className="flex flex-wrap md:flex-nowrap justify-center   gap-5">
                            <FreePlanCard tenure={activeIndex ? 'month':'yearly'}/>
                            {
                                currentPlans.map((item, index) => (
                                    <PlanCard key={index} index={index} {...item} isActive={false} handlePayment={handlePayment} isPending={isPending} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PaymentPending;
