import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../redux/slice/auth.slice";
import { loginTokenService } from "../../services/auth.service";
import LoaderGif from "../../asset/gif/loader.gif";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";

const LoginToken = () => {
    const authState = useSelector((state) => state.auth);
    const isAuthenticated = authState.isLoggedIn;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleLoginWithToken = async () => {
            const windowUrl = window.location.href;
            const tokenMatch = windowUrl.match(/token=([^&]+)/);

            if (tokenMatch && tokenMatch[1]) {
                try {
                    const userData = await loginTokenService({ token: tokenMatch[1] });
                    dispatch(login(userData));
                    trackEvent(MIXPANEL_EVENTS.LOGIN_SUCCESS, { "Login method": "magic link" });
                    navigate('/onboarding');
                } catch (error) {
                    trackEvent(MIXPANEL_EVENTS.LOGIN_FAILURE, {
                        "Reason (log in failure)": error?.message || "Server Error"
                    });
                    toast.error(error?.message || "Login failed. Please try again.");
                    navigate('/');
                }
            } else {
                navigate('/login')
            }
        };

        if (!isAuthenticated) {
            handleLoginWithToken();
        }
    }, [dispatch, navigate]);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <img src={LoaderGif} width={200} alt="loading" />
            <p className="text-gray-500">Redirecting to login</p>
        </div>
    );
};

export default LoginToken;
