import { CompositeDecorator } from 'draft-js';

// Reusable decorator creation function
export const createDecorator = (strategy, className) => {
    return new CompositeDecorator([
        {
            strategy,
            component: (props) => (
                <span {...props} className={className}>
                    {props.children}
                </span>
            ),
        },
    ]);
};

// Hook Strategy: Applies only to the first block and checks if the first paragraph has more than 200 characters
export const hookStrategy = (contentBlock, callback, contentState) => {
    const firstBlock = contentState.getBlocksAsArray()[0];

    // Only process the first block
    if (contentBlock.getKey() !== firstBlock.getKey()) {
        return;
    }

    const content = firstBlock.getText();
    const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '');

    if (paragraphs.length > 0 && paragraphs[0].length > 200) {
        callback(0, paragraphs[0].length);
    }
};

// Spacing Strategy: Finds paragraphs longer than 250 characters and highlights them
export const spacingStrategy = (contentBlock, callback, contentState) => {
    const content = contentBlock.getText();
    const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '');

    paragraphs.forEach(paragraph => {
        if (paragraph.length > 250) {
            const start = content.indexOf(paragraph);
            callback(start, start + paragraph.length);
        }
    });
};

// Hashtag Strategy: Highlights hashtags if there are more than 4 in the entire content
export const hashtagStrategy = (contentBlock, callback, contentState) => {
    // Get all blocks in the editor
    const allBlocks = contentState.getBlocksAsArray();

    // Regex to find all hashtags
    const hashtagRegex = /#[a-z0-9_]+/gi;

    // Variable to store total hashtags found
    let totalHashtags = 0;

    // Count hashtags across all blocks
    allBlocks.forEach(block => {
        const blockText = block.getText();
        const blockHashtags = (blockText.match(hashtagRegex) || []).length;
        totalHashtags += blockHashtags;
    });

    // Only proceed if there are more than 4 hashtags
    if (totalHashtags > 4) {
        // Get the text of the current block
        const text = contentBlock.getText();
        let matchArr;

        // Use regex to find and highlight hashtags in the current block
        while ((matchArr = hashtagRegex.exec(text)) !== null) {
            const start = matchArr.index;
            callback(start, start + matchArr[0].length); // Highlight the hashtag
        }
    }
};


// Emoji Strategy: Highlights emojis if there are more than 4 in the entire content
export const emojiStrategy = (contentBlock, callback, contentState) => {
    // Get all blocks in the editor
    const allBlocks = contentState.getBlocksAsArray();

    // Regex to find all emojis (using Unicode range for common emojis)
    const emojiRegex = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;;

    // Variable to store total emojis found
    let totalEmojis = 0;

    // Count emojis across all blocks
    allBlocks.forEach(block => {
        const blockText = block.getText();
        const blockEmojis = (blockText.match(emojiRegex) || []).length;
        totalEmojis += blockEmojis;
    });

    // Only proceed if there are more than 4 emojis
    if (totalEmojis > 4) {
        // Get the text of the current block
        const text = contentBlock.getText();
        let matchArr;

        // Use regex to find and highlight emojis in the current block
        while ((matchArr = emojiRegex.exec(text)) !== null) {
            const start = matchArr.index;
            callback(start, start + matchArr[0].length); // Highlight the emoji
        }
    }
};

let currentWordcount = 0;

function getWordsWithIndexes(paragraph) {
    const words = [];
    let match;
    // Regular expression to match words and their index
    const regex = /\S+/g;

    // Loop through all matches
    while ((match = regex.exec(paragraph)) !== null) {
        words.push({ word: match[0], index: match.index });
    }

    return words;
}

export const contentLengthStrategy = (contentBlock, callback, contentState) => {
    const MAX_WORDS = 400;
    const firstBlock = contentState.getBlocksAsArray()[0];

    // Only process the first block
    if (contentBlock.getKey() === firstBlock.getKey()) {
        currentWordcount = 0;
    }

    const text = contentBlock.getText();
    const wordCount = getWordsWithIndexes(text);

    // Check how many words we have already processed
    const remainingWords = MAX_WORDS - currentWordcount;

    if (remainingWords > 0) {
        // If current block doesn't exceed the limit, update currentWordcount
        if (wordCount.length <= remainingWords) {
            currentWordcount += wordCount.length;
            // No need to trigger callback if under the limit
        } else {
            // If we exceed the word limit in this block, find the MAX_WORDSth word
            const callbackIndex = wordCount[remainingWords] ? wordCount[remainingWords].index : text.length;
            currentWordcount = MAX_WORDS; // Max out the word count

            callback(callbackIndex, text.length);
        }
    } else {
        // If we've already reached or exceeded the word limit
        callback(0, text.length);
    }
};
export const formattingStrategy = (contentBlock, callback, contentState) => {
    let totalBoldLength = 0;
    let totalItalicLength = 0;
    let totalUnderlineLength = 0;

    const allText = contentState.getPlainText();
    const totalLength = allText.length;

    // 1. Calculate total counts for BOLD, ITALIC, and UNDERLINE in all content blocks
    contentState.getBlockMap().forEach(block => {
        block.getCharacterList().forEach(char => {
            const styles = char.getStyle();
            if (styles.has('BOLD')) totalBoldLength++;
            if (styles.has('ITALIC')) totalItalicLength++;
            if (styles.has('UNDERLINE')) totalUnderlineLength++;
        });
    });

    // 2. Calculate percentages
    const boldPercentage = (totalBoldLength / totalLength) * 100;
    const italicPercentage = (totalItalicLength / totalLength) * 100;
    const underlinePercentage = (totalUnderlineLength / totalLength) * 100;

    // 3. Check if any percentage exceeds 20%, then underline in the current contentBlock
    if (boldPercentage > 20) {
        underlineUnformattedText('BOLD', contentBlock, callback);
    }

    if (italicPercentage > 20) {
        underlineUnformattedText('ITALIC', contentBlock, callback);
    }

    if (underlinePercentage > 20) {
        underlineUnformattedText('UNDERLINE', contentBlock, callback);
    }
};


export const underlineUnformattedText = (styleToCheck, contentBlock, callback) => {
    let startIndex = null;
    let endIndex = null;

     contentBlock.getCharacterList().map((char, idx) => {
        const styles = char.getStyle();

        // If the character has the target style (BOLD, ITALIC, or UNDERLINE), add UNDERLINE
        if (styles.has(styleToCheck)) {
            if (startIndex === null) startIndex = idx; // Start the range
            endIndex = idx + 1; // End of range

            // Add 'UNDERLINE' to the character's styles
            const newStyles = styles.add('UNDERLINE');
            return char.set('style', newStyles);
        } else {
            // If the style ends, trigger callback for the range
            if (startIndex !== null) {
                callback(startIndex, endIndex);
                startIndex = null;
            }
        }

        return char;
    });

    // Ensure the last range is captured
    if (startIndex !== null) {
        callback(startIndex, endIndex);
    }

    // Optionally, return the block with the updated character list if you need to update it in the contentState
    // return contentBlock.set('characterList', newCharList);
};



// Create decorators with different styles
export const hookDecorator = createDecorator(hookStrategy, 'border-b-2 border-venus-light');
export const contentLengthDecorator = createDecorator(contentLengthStrategy, 'border-b-2 border-venus-light');
export const spacingDecorator = createDecorator(spacingStrategy, 'bg-venus-light2 inline-block');
export const hashtagDecorator = createDecorator(hashtagStrategy, 'border-b-2 border-venus-light');
export const emojiDecorator = createDecorator(emojiStrategy, 'border-b-2 border-venus-light');
export const formattingDecorator = createDecorator(formattingStrategy, 'border-b-2 border-venus-light');
