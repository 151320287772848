const ViralTemplateSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" color="currentColor">
        <path d="M3.3335 15.7002L3.3335 4.30024C3.3335 3.30613 4.13938 2.50024 5.1335 2.50024L14.8668 2.50024C15.8609 2.50024 16.6668 3.30613 16.6668 4.30024L16.6668 15.7002C16.6668 16.6944 15.8609 17.5002 14.8668 17.5002L5.1335 17.5002C4.13938 17.5002 3.3335 16.6944 3.3335 15.7002Z" stroke="currentColor" strokeWidth="1.25" stroke-miterlimit="5.24084"/>
        <path d="M6.66675 11.2515L13.3334 11.2515" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round"/>
        <path d="M6.66675 14.1321L9.52759 14.1321" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round"/>
        <path d="M13.5825 4.97291V6.72005C13.5825 6.77797 13.5556 6.83352 13.5076 6.87448C13.4596 6.91543 13.3945 6.93844 13.3266 6.93844C13.2588 6.93844 13.1937 6.91543 13.1457 6.87448C13.0977 6.83352 13.0707 6.77797 13.0707 6.72005V5.50005L10.437 7.74813C10.4132 7.76844 10.385 7.78454 10.354 7.79554C10.3229 7.80653 10.2896 7.81218 10.256 7.81218C10.2223 7.81218 10.189 7.80653 10.158 7.79554C10.1269 7.78454 10.0987 7.76844 10.0749 7.74813L8.97651 6.81041L6.85455 8.6217C6.80654 8.66268 6.74142 8.6857 6.67351 8.6857C6.60561 8.6857 6.54049 8.66268 6.49247 8.6217C6.44446 8.58072 6.41748 8.52514 6.41748 8.46719C6.41748 8.40923 6.44446 8.35365 6.49247 8.31267L8.79547 6.34714C8.81924 6.32684 8.84746 6.31073 8.87852 6.29974C8.90959 6.28875 8.94289 6.28309 8.97651 6.28309C9.01014 6.28309 9.04344 6.28875 9.0745 6.29974C9.10557 6.31073 9.13379 6.32684 9.15756 6.34714L10.256 7.28487L12.709 5.1913H11.354C11.2862 5.1913 11.2211 5.16829 11.1731 5.12734C11.1251 5.08638 11.0981 5.03083 11.0981 4.97291C11.0981 4.91499 11.1251 4.85944 11.1731 4.81848C11.2211 4.77753 11.2862 4.75452 11.354 4.75452H13.3266C13.3945 4.75452 13.4596 4.77753 13.5076 4.81848C13.5556 4.85944 13.5825 4.91499 13.5825 4.97291Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
      </svg>
    )
}
export default ViralTemplateSVG;