const SoloLogo = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="55" height="46" viewBox="0 0 55 46" fill="none">
            <g opacity="0.12" clipPath="url(#clip0_87_1905)">
                <path d="M9.96164 0.5C9.64187 0.499997 9.32703 0.58021 9.04496 0.733543C8.76289 0.886876 8.52228 1.1086 8.34442 1.37912L0.328786 13.5703C0.115095 13.8953 0.000695767 14.2775 3.16359e-06 14.6686C-0.00068944 15.0597 0.112356 15.4422 0.324894 15.768L26.1004 55.2765C26.1632 55.3729 26.2573 55.4439 26.3662 55.477C26.4751 55.5102 26.592 55.5035 26.6966 55.4581C26.8012 55.4127 26.8868 55.3315 26.9387 55.2285C26.9905 55.1255 27.0053 55.0073 26.9805 54.8943L19.8046 22.2382C19.7468 21.9751 19.7423 21.7029 19.7915 21.438C19.8406 21.1732 19.9424 20.9213 20.0905 20.6979L30.0895 5.60967C30.1781 5.47588 30.2976 5.36617 30.4374 5.29008C30.5772 5.21399 30.7331 5.17383 30.8917 5.17309C31.0502 5.17235 31.2065 5.21106 31.347 5.28584C31.4875 5.36063 31.6079 5.46922 31.6977 5.60218L41.805 20.5567C41.9571 20.7817 42.0618 21.0365 42.1126 21.3047C42.1634 21.573 42.1591 21.8491 42.1 22.1156L38.2608 39.4295C38.2358 39.5424 38.2504 39.6606 38.302 39.7636C38.3537 39.8667 38.4392 39.9481 38.5437 39.9937C38.6481 40.0393 38.765 40.0462 38.8739 40.0132C38.9829 39.9803 39.077 39.9096 39.1401 39.8133L54.887 15.7688C55.1005 15.4429 55.2142 15.0599 55.2138 14.6682C55.2135 14.2765 55.0991 13.8937 54.8851 13.5682L46.8709 1.37911C46.693 1.10861 46.4524 0.886887 46.1703 0.733556C45.8883 0.580226 45.5734 0.500009 45.2536 0.5H9.96164Z" fill="url(#paint0_linear_87_1905)" />
                <path d="M30.2532 19.8487L30.7415 18.0027L31.2297 19.8487C31.3923 20.4635 31.7107 21.024 32.1529 21.4742C32.5951 21.9243 33.1458 22.2484 33.7497 22.4139L35.5631 22.911L33.7497 23.408C33.1458 23.5735 32.5951 23.8976 32.1529 24.3478C31.7107 24.7979 31.3923 25.3585 31.2297 25.9732L30.7415 27.8192L30.2532 25.9732C30.0906 25.3585 29.7722 24.7979 29.33 24.3478C28.8878 23.8976 28.3371 23.5735 27.7332 23.408L25.9197 22.911L27.7332 22.4139C28.3371 22.2484 28.8877 21.9244 29.33 21.4742C29.7722 21.024 30.0906 20.4635 30.2532 19.8487Z" fill="url(#paint1_linear_87_1905)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_87_1905" x1="11.8037" y1="60.2951" x2="56.787" y2="51.9218" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.709584" stopColor="#FDA2F5" />
                </linearGradient>
                <linearGradient id="paint1_linear_87_1905" x1="27.9813" y1="28.6755" x2="35.8491" y2="27.2424" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.709584" stopColor="#FDA2F5" />
                </linearGradient>
                <clipPath id="clip0_87_1905">
                    <rect width="55" height="55" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default SoloLogo;