import { Refresh, Xmark } from 'iconoir-react';
import PaymentFailedIcon from '../../asset/svg/paymentFailed.svg';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { paymentVerified, subscribePlan } from '../../services/payment.service';
import { toast } from 'react-toastify';
import { RAZORPAY_KEY } from '../../utils/config';
import { updateSubscription } from '../../redux/slice/auth.slice';
import { useState } from 'react';
import PaymentSuccessModel from './PaymentSucessModel';
import moment from 'moment';

const LeftSvg = () => {
    return (
        <svg width="138" height="335" viewBox="0 0 138 335" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.65503 3.78198L110.922 115.764C137.954 144.25 138.436 188.765 112.026 217.829L9.49057 330.667" stroke="url(#paint0_linear_15_61)" stroke-width="11" />
            <defs>
                <linearGradient id="paint0_linear_15_61" x1="31.2993" y1="286.735" x2="199.146" y2="134.235" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const RightSvg = () => {
    return (
        <svg width="186" height="60" viewBox="0 0 186 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29876 46.674C-0.426666 49.174 0.201193 52.5993 2.70112 54.3247C5.20107 56.0502 8.62641 55.4223 10.3518 52.9224L1.29876 46.674ZM109.507 15.0567L112.097 10.2046L109.507 15.0567ZM185.756 49.5227L112.097 10.2046L106.918 19.9087L180.576 59.2268L185.756 49.5227ZM9.23181 35.18L1.29876 46.674L10.3518 52.9224L18.2849 41.4283L9.23181 35.18ZM112.097 10.2046C76.445 -8.82623 32.1881 1.91919 9.23181 35.18L18.2849 41.4283C38.0648 12.7697 76.1981 3.51103 106.918 19.9087L112.097 10.2046Z" fill="url(#paint0_linear_15_60)" />
            <defs>
                <linearGradient id="paint0_linear_15_60" x1="-1.97575" y1="68.576" x2="214.743" y2="-2.45139" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const PaymentFailed = () => {
    const { subscription } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [openSuccess, setOpenSuccess] = useState(false);

    const { mutate, isPending } = useMutation({
        mutationFn: subscribePlan,
        onSuccess: (data) => {
            if (!data.data?.subscriptionId) {
                toast.success(data.message);
                return;
            }

            const options = {
                key: RAZORPAY_KEY,  // Replace with Razorpay Key ID
                subscription_id: data.data.subscriptionId,
                name: 'SuperPen',
                description: 'Subscription Payment',
                handler: async (response) => {
                    try {
                        const result = await paymentVerified(response);
                        dispatch(updateSubscription(result.data))
                        setOpenSuccess(true);
                    } catch (error) {
                        console.log(error);
                        toast.error('Payment was successful, but there was an error processing your request on the server. Please try again later.');
                    }
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment Model Closed');
                    },
                },
                theme: {
                    color: '#7145FD',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        },
        onError: (data) => {
            toast.error(data.message || 'Server Error');
        }
    });

    const handlePayment = () => {
        if (subscription) {
            mutate({
                planId: subscription.planId,
            });
        }
    };
    const currencySymbol = subscription?.currency === 'INR' ? '₹' : '$';

    if (subscription && subscription.endDate) {
        const today = moment(); // Get today's date
        const endDate = moment(subscription.endDate);

        const daysLeft = endDate.diff(today, 'days');

        if (daysLeft >= 0) {
            return <Navigate to='/' />;
        }
    }
    return (
        <>
            {openSuccess && (
                <PaymentSuccessModel
                    amount={`${currencySymbol}${subscription.amountPaid}`}
                    onClose={() => {
                        setOpenSuccess(false);
                    }}
                />
            )}
            <div className='h-screen top-0 z-50 flex justify-center items-center w-screen fixed'>
                <div className="h-screen top-0 z-10 w-full absolute blur-xl bg-[#6A65A785]" />
                <div className="bg-white rounded-3xl p-8 z-30 relative flex items-center text-center flex-col w-[625px] overflow-hidden ">
                    <div className='absolute -left-[60px] -bottom-[100px] z-10 '>
                        <LeftSvg />
                    </div>
                    <div className='absolute -right-1  top-[50px] z-10'>
                        <RightSvg />
                    </div>

                    <div className='w-[265px]'>
                        <img src={PaymentFailedIcon} alt='payment-success' className='w-full h-full' />
                    </div>
                    <p className='text-venus-default text-bodyOneBold'>Your payment couldn’t be completed</p>
                    <p className='text-gray-6 text-bodyTwo'>Your payment of  {currencySymbol}{subscription.amountPaid} has been processed </p>
                    <button className='filled-btn mt-8 red gap-2' disabled={isPending} onClick={handlePayment}>
                        <Refresh width={22} height={22} />  Try Again
                    </button>
                </div>
            </div>
        </>
    )
}
export default PaymentFailed;