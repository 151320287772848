const SegmentedBtn = ({ list, activeIndex, onChange }) => {
    
    return (
        <div className="inline-flex overflow-hidden rounded-xl border border-star-light">
            {
                list.map((item, index) => {
                    return (
                        <div key={index} className={`px-4 py-2 text-center text-bodyThreeBold cursor-pointer w-[177px] ${activeIndex === index ? 'bg-space-default text-white' : 'bg-gray-moon text-star-default'}`} onClick={() => onChange(index)}>
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SegmentedBtn;