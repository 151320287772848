const LogoIcon = () => {
    return (
        <svg width="102" height="24" viewBox="0 0 102 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_5171)">
                <path d="M36.7833 8.01577C36.5325 7.70741 36.2107 7.4623 35.8445 7.30077C35.496 7.13259 35.1141 7.04208 34.726 7.03571C34.5373 7.03602 34.3489 7.05212 34.1629 7.08383C33.9811 7.11269 33.8066 7.17543 33.6487 7.2687C33.497 7.35879 33.3662 7.4793 33.2649 7.6224C33.1557 7.78651 33.1014 7.98015 33.1096 8.17616C33.1034 8.34501 33.1459 8.51214 33.2323 8.65817C33.3237 8.79662 33.4466 8.91223 33.5913 8.99583C33.7699 9.10205 33.9586 9.19082 34.1548 9.26089C34.3722 9.34108 34.6171 9.42465 34.8894 9.50991C35.281 9.63822 35.6891 9.78004 36.1138 9.93536C36.5283 10.0861 36.9186 10.2945 37.273 10.5541C37.6228 10.8135 37.9168 11.1386 38.1381 11.5105C38.3805 11.9453 38.499 12.4365 38.4811 12.9321C38.4962 13.5177 38.3703 14.0986 38.1136 14.6271C37.8806 15.09 37.5427 15.4941 37.1259 15.8081C36.701 16.1246 36.2191 16.3592 35.7056 16.4995C35.1692 16.6494 34.6142 16.725 34.0566 16.724C33.2371 16.7254 32.4241 16.5812 31.6567 16.2985C30.9292 16.0436 30.2703 15.6287 29.7302 15.0855L31.5589 13.2537C31.8655 13.6151 32.2468 13.908 32.6773 14.1131C33.1027 14.3345 33.5754 14.4531 34.0566 14.4592C34.2598 14.4594 34.4625 14.4376 34.6608 14.3942C34.844 14.3569 35.0184 14.2861 35.175 14.1856C35.323 14.088 35.4436 13.9554 35.5258 13.7999C35.6173 13.6207 35.6622 13.422 35.6566 13.2216C35.6626 13.0271 35.6054 12.8358 35.4932 12.6755C35.3672 12.5098 35.209 12.3704 35.0279 12.2652C34.7909 12.1262 34.539 12.0133 34.277 11.9284C33.9776 11.8263 33.6375 11.7162 33.2567 11.5983C32.8866 11.4808 32.524 11.3416 32.1709 11.1813C31.8245 11.025 31.5051 10.8162 31.2244 10.5626C30.9416 10.3041 30.714 9.99273 30.5549 9.64666C30.3751 9.23346 30.2888 8.78671 30.3019 8.33739C30.2853 7.77184 30.4204 7.21196 30.6935 6.71409C30.9466 6.27269 31.2984 5.8937 31.7223 5.60573C32.1586 5.31107 32.6453 5.09605 33.159 4.97093C33.6869 4.83749 34.2298 4.76999 34.7749 4.77002C35.4572 4.77527 36.1337 4.89488 36.7751 5.12372C37.4368 5.34884 38.045 5.70411 38.5626 6.16792L36.7833 8.01577Z" fill="#120B2A" />
                <path d="M45.5337 16.4353V15.3261H45.5011C45.3987 15.5144 45.2696 15.6875 45.1177 15.8401C44.9544 16.0055 44.7673 16.1463 44.5623 16.258C44.3401 16.3802 44.1046 16.4773 43.8603 16.5475C43.598 16.6235 43.3258 16.6613 43.0523 16.6598C42.5755 16.6744 42.1022 16.5753 41.6727 16.3711C41.3151 16.1924 41.0028 15.9372 40.7588 15.624C40.5212 15.3122 40.349 14.9569 40.2523 14.579C40.149 14.1881 40.0969 13.7858 40.0975 13.382V8.43359H42.7912V12.7717C42.7904 12.9867 42.8068 13.2015 42.84 13.4141C42.8684 13.6058 42.9321 13.7909 43.0279 13.9602C43.1147 14.1157 43.2414 14.2461 43.3954 14.3384C43.5802 14.4374 43.7891 14.4847 43.9993 14.4752C44.2073 14.486 44.4148 14.4444 44.6019 14.3543C44.7891 14.2641 44.9498 14.1285 45.0688 13.9602C45.3172 13.6068 45.4457 13.1852 45.436 12.7556V8.43359H48.1133V16.4353H45.5337Z" fill="#120B2A" />
                <path d="M59.2474 12.4017C59.2492 12.9478 59.161 13.4906 58.9862 14.009C58.8225 14.5045 58.5649 14.9651 58.2271 15.3664C57.8927 15.7596 57.4784 16.0796 57.0109 16.3059C56.5023 16.5481 55.9431 16.6693 55.3782 16.6596C54.8886 16.662 54.4041 16.5607 53.9578 16.3625C53.5424 16.1885 53.1817 15.9084 52.9131 15.5512H52.8805V20.2911H50.2031V8.43338H52.7497V9.41343H52.7986C53.0816 9.06505 53.4346 8.77795 53.8356 8.57013C54.3149 8.31945 54.8519 8.19472 55.3945 8.20799C55.9469 8.2007 56.4938 8.31612 56.9946 8.54565C57.4619 8.76174 57.8767 9.07408 58.2108 9.46155C58.5489 9.85794 58.8091 10.3128 58.9781 10.8029C59.1585 11.3171 59.2496 11.8577 59.2474 12.4017ZM56.6519 12.4017C56.6518 12.1454 56.6104 11.8906 56.5293 11.647C56.4526 11.4097 56.3308 11.1888 56.1703 10.9962C55.9964 10.7911 55.7775 10.6274 55.53 10.5175C55.2826 10.4076 55.0131 10.3542 54.7418 10.3614C54.4593 10.3566 54.1796 10.417 53.9252 10.5378C53.6917 10.6515 53.4837 10.8099 53.3132 11.0038C53.1433 11.1985 53.0105 11.4219 52.9213 11.6631C52.736 12.15 52.736 12.6864 52.9213 13.1733C53.0103 13.4143 53.1431 13.6374 53.3132 13.8317C53.4832 14.0261 53.6914 14.1846 53.9252 14.2977C54.1794 14.4192 54.4592 14.4799 54.7418 14.4749C55.0295 14.481 55.3147 14.4202 55.5742 14.2977C55.805 14.1869 56.0085 14.0279 56.1703 13.8317C56.3312 13.6334 56.4529 13.4072 56.5293 13.1648C56.6101 12.9182 56.6515 12.6608 56.6519 12.4017Z" fill="#120B2A" />
                <path d="M68.9942 12.5301V12.8518C68.9944 12.9538 68.989 13.0558 68.9779 13.1573H63.0845C63.1033 13.3736 63.173 13.5825 63.2882 13.7676C63.4041 13.9512 63.5536 14.1121 63.7291 14.2421C63.9122 14.3775 64.1162 14.4832 64.3333 14.5552C64.5567 14.6303 64.7912 14.6682 65.0272 14.6675C65.4004 14.679 65.7706 14.5989 66.1045 14.4345C66.3867 14.2889 66.6321 14.083 66.8229 13.8318L68.6841 14.9891C68.304 15.5345 67.7827 15.9699 67.1741 16.2503C66.4905 16.5567 65.7456 16.7076 64.9946 16.6918C64.4057 16.6952 63.8204 16.6001 63.2637 16.4107C62.737 16.2335 62.2513 15.9549 61.8352 15.591C61.4205 15.223 61.0918 14.7709 60.872 14.2657C60.629 13.6964 60.5095 13.0834 60.5212 12.466C60.5123 11.8575 60.6289 11.2536 60.8638 10.6907C61.0771 10.183 61.3938 9.72358 61.7945 9.34095C62.1943 8.96639 62.6661 8.67422 63.1823 8.48161C63.7347 8.2746 64.3216 8.17097 64.9127 8.17604C65.4767 8.16997 66.0365 8.27085 66.5617 8.47318C67.0504 8.66376 67.4907 8.95737 67.8517 9.33337C68.2229 9.72795 68.5089 10.1926 68.6923 10.6992C68.9023 11.2871 69.0046 11.907 68.9942 12.5301ZM66.5291 11.518C66.5375 11.1226 66.3947 10.7385 66.129 10.4417C65.9748 10.2846 65.7874 10.1628 65.58 10.0851C65.3726 10.0073 65.1503 9.97546 64.929 9.99179C64.6841 9.98916 64.4408 10.0301 64.2107 10.1125C64.0017 10.1877 63.8082 10.2992 63.6394 10.4417C63.478 10.5789 63.3449 10.7454 63.2474 10.9322C63.1512 11.1137 63.0956 11.3135 63.0845 11.518L66.5291 11.518Z" fill="#120B2A" />
                <path d="M75.9327 10.6019C75.8178 10.5708 75.7005 10.5494 75.5819 10.5377C75.4674 10.5276 75.3559 10.5217 75.247 10.5217C74.9334 10.5117 74.6222 10.5778 74.3408 10.7142C74.1144 10.8276 73.9143 10.986 73.7532 11.1793C73.6094 11.3548 73.5014 11.556 73.435 11.7719C73.3749 11.9531 73.3418 12.1419 73.3368 12.3324V16.435H70.6594V8.44257H73.239V9.59989H73.2716C73.4727 9.19611 73.7764 8.85036 74.1534 8.5962C74.5426 8.3351 75.0046 8.19942 75.4755 8.20789C75.5845 8.20789 75.6904 8.2138 75.7942 8.22393C75.88 8.23154 75.9647 8.24766 76.0472 8.27205L75.9327 10.6019Z" fill="#120B2A" />
                <path d="M77.6143 5.05896H80.8796C81.4187 5.05323 81.9562 5.11536 82.4793 5.24383C82.9233 5.35102 83.342 5.54194 83.7122 5.80603C84.0513 6.05319 84.3232 6.37879 84.5039 6.75401C84.6976 7.17494 84.7924 7.63341 84.7814 8.09536C84.7926 8.55817 84.6949 9.01727 84.4958 9.43671C84.3103 9.8113 84.036 10.1364 83.6959 10.3847C83.3229 10.6509 82.9016 10.8445 82.4548 10.9553C81.9298 11.0889 81.3893 11.1539 80.847 11.1486H78.7898V16.4347H77.6143V5.05896ZM78.7898 10.1677H80.7814C81.523 10.2173 82.2612 10.0315 82.8875 9.63761C83.1263 9.45798 83.3167 9.22344 83.4419 8.95455C83.5672 8.68566 83.6235 8.39064 83.6059 8.09536C83.6293 7.79568 83.5732 7.49515 83.443 7.22328C83.3128 6.9514 83.1129 6.71754 82.8631 6.54466C82.233 6.18399 81.5091 6.01371 80.7814 6.05505H78.7898V10.1677Z" fill="#120B2A" />
                <path d="M92.7973 12.4497V12.6911C92.7969 12.7822 92.7915 12.8733 92.781 12.9638H86.4467C86.4552 13.3284 86.5327 13.6883 86.6752 14.0249C86.8111 14.3491 87.0108 14.6438 87.2628 14.8918C87.5161 15.1408 87.8154 15.3399 88.1445 15.4785C88.5605 15.6473 89.0105 15.7192 89.4594 15.6886C89.9083 15.658 90.3439 15.5257 90.7323 15.3021C91.1158 15.0736 91.4422 14.7632 91.6873 14.3938L92.5198 15.037C92.1229 15.5569 91.605 15.9755 91.0098 16.2577C90.4535 16.5055 89.8492 16.6316 89.2385 16.6274C88.6985 16.6325 88.1628 16.5315 87.6629 16.3303C87.1932 16.142 86.7681 15.8603 86.4141 15.503C86.0601 15.141 85.7802 14.7154 85.5894 14.2494C85.3844 13.7493 85.2818 13.2143 85.2875 12.6751C85.2818 12.1363 85.3816 11.6016 85.5812 11.0999C85.767 10.6335 86.0445 10.2078 86.3978 9.84719C86.7455 9.49356 87.1619 9.21225 87.6222 9.01992C88.1021 8.81902 88.6189 8.71758 89.1403 8.72193C89.6836 8.7114 90.2231 8.81291 90.7241 9.01992C91.1598 9.20274 91.5498 9.47667 91.867 9.82271C92.1756 10.1671 92.4113 10.5686 92.5605 11.0037C92.7209 11.4693 92.801 11.9581 92.7973 12.4497ZM91.6711 12.0969C91.6614 11.4473 91.4152 10.8228 90.9772 10.3368C90.7404 10.0968 90.4536 9.90998 90.1366 9.78933C89.8195 9.66868 89.4797 9.617 89.1404 9.63785C88.7872 9.63481 88.4372 9.70319 88.112 9.83876C87.8025 9.9688 87.5202 10.154 87.2791 10.3849C87.0424 10.6118 86.849 10.8785 86.7079 11.1725C86.5676 11.4626 86.4845 11.7763 86.463 12.0969H91.6711Z" fill="#120B2A" />
                <path d="M96.1767 8.9153C96.1986 9.11874 96.2149 9.34357 96.2256 9.58978C96.2368 9.83627 96.2422 10.04 96.2419 10.2009H96.2745C96.5077 9.74541 96.8785 9.3721 97.3359 9.13225C97.8073 8.86356 98.3424 8.72204 98.8871 8.72199C99.2922 8.69262 99.6988 8.75401 100.076 8.90153C100.454 9.04906 100.792 9.27889 101.067 9.57374C101.575 10.2135 101.833 11.012 101.793 11.8234V16.435H100.683V12.2733C100.686 11.9123 100.653 11.5518 100.585 11.197C100.53 10.9019 100.416 10.6204 100.25 10.3689C100.09 10.1373 99.8705 9.95172 99.6136 9.83121C99.2981 9.69239 98.9546 9.62627 98.6092 9.6379C98.3142 9.63815 98.0225 9.69851 97.7524 9.81517C97.4674 9.93809 97.2137 10.1218 97.0096 10.3529C96.7761 10.6215 96.5986 10.9327 96.4872 11.2688C96.3482 11.6917 96.282 12.1345 96.2912 12.5789V16.435H95.1809V10.5698C95.1809 10.3664 95.1755 10.1042 95.1646 9.7831C95.1539 9.46148 95.1376 9.17222 95.1157 8.91532L96.1767 8.9153Z" fill="#120B2A" />
                <path d="M4.4961 0C4.35178 -1.16791e-06 4.20968 0.0350006 4.08237 0.10191C3.95506 0.168819 3.84646 0.265573 3.76619 0.383615L0.148395 5.7034C0.0519473 5.84523 0.000314029 6.01199 1.42786e-06 6.18265C-0.000311173 6.35331 0.0507109 6.52025 0.146638 6.66242L11.7802 23.9025C11.8086 23.9445 11.851 23.9755 11.9002 23.99C11.9493 24.0045 12.0021 24.0015 12.0493 23.9817C12.0965 23.9619 12.1351 23.9265 12.1585 23.8815C12.1819 23.8366 12.1886 23.785 12.1774 23.7357L8.93864 9.48575C8.91254 9.37097 8.91053 9.25216 8.93271 9.13659C8.9549 9.02102 9.00082 8.91112 9.06766 8.81363L13.5806 2.22967C13.6206 2.17129 13.6745 2.12342 13.7376 2.09022C13.8007 2.05701 13.8711 2.03949 13.9427 2.03917C14.0142 2.03885 14.0848 2.05574 14.1482 2.08837C14.2116 2.121 14.266 2.16839 14.3065 2.22641L18.8683 8.75199C18.937 8.85022 18.9843 8.96136 19.0072 9.07844C19.0301 9.19551 19.0281 9.31598 19.0015 9.43228L17.2687 16.9874C17.2574 17.0367 17.264 17.0883 17.2873 17.1332C17.3106 17.1782 17.3492 17.2137 17.3964 17.2336C17.4435 17.2535 17.4962 17.2565 17.5454 17.2421C17.5946 17.2278 17.6371 17.1969 17.6656 17.1549L24.7728 6.66276C24.8691 6.52055 24.9205 6.35342 24.9203 6.1825C24.9201 6.01158 24.8685 5.84454 24.7719 5.70249L21.1548 0.383611C21.0745 0.265574 20.9659 0.168823 20.8386 0.101915C20.7113 0.0350076 20.5692 4.0452e-06 20.4249 0L4.4961 0Z" fill="#7145FD" />
                <path d="M13.6545 8.44323L13.8749 7.6377L14.0953 8.44323C14.1687 8.71148 14.3124 8.95607 14.512 9.15251C14.7116 9.34895 14.9601 9.49036 15.2326 9.56259L16.0511 9.77948L15.2326 9.99637C14.9601 10.0686 14.7116 10.21 14.512 10.4064C14.3124 10.6029 14.1687 10.8475 14.0953 11.1157L13.8749 11.9213L13.6545 11.1157C13.5811 10.8475 13.4375 10.6029 13.2379 10.4064C13.0383 10.21 12.7897 10.0686 12.5172 9.99637L11.6987 9.77948L12.5172 9.56259C12.7897 9.49036 13.0383 9.34895 13.2379 9.15251C13.4375 8.95607 13.5811 8.71149 13.6545 8.44323Z" fill="#7145FD" />
            </g>
            <defs>
                <clipPath id="clip0_3_5171">
                    <rect width="101.793" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default LogoIcon;