import { useEffect, useRef, useState } from "react";
import { WarningCircle } from "iconoir-react";
import { Controller } from "react-hook-form";
const InputField = ({ name, control, ...other }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="w-full">
                    <div className="relative">
                        <input {...field} className="rounded-lg text-bodyTwo text-space-default bg-gray-moon  py-2.5 px-3.5 w-full"
                            style={{
                                border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                                backgroundImage: hasFocus ? 'linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'padding-box, border-box',
                                outline: 'none'
                            }}
                            ref={ref}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            {...other}
                        />
                        {!!error && (
                            <div className="absolute right-4 top-2.5">
                                <WarningCircle height={'24px'} width={'24px'} color="#FD6483" />
                            </div>
                        )}
                    </div>
                    {!!error && (
                        <p className="text-bodyThree text-venus-default">{error?.message}</p>
                    )}
                </div>
            )}
        />

    )
}
export default InputField;