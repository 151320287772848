const recommendation = {
    founder: ["entrepreneurship", "startup", "funding"],
    freelancer: ["networking", "sales", "freelancing"],
    student: ["networking", "entrepreneurship", "college"],
    "working professional": ["leadership", "journey", "teamwork"],
    creator: ["personal branding", "marketing", "networking"],
    others: ["networking", "leadership", "growth"]
};

export const getRecommendation = (text='') => {
    const formattedText = text.toLowerCase();
    return recommendation[formattedText] || recommendation['others'];
};


