import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../config';

mixpanel.init(MIXPANEL_TOKEN, { persistence: "localStorage" });

export const trackEvent = (eventName, properties = {}) => {
    mixpanel.track(eventName, properties);
};

export const identifyUser = (userId, properties = {}) => {
    mixpanel.identify(userId);
    mixpanel.people.set({ ...properties, $onesignal_user_id: userId });
};

export const setUserProperties = (properties = {}) => {
    mixpanel.people.set(properties);
};
export const incrementUserProperty = (propertyName, count = 1) => {
    mixpanel.people.increment(propertyName, count);
};

export const resetMixpanel = () => {
    mixpanel.reset();
}
export const setSuperProperties = (properties = {}) => {
    mixpanel.register(properties)
}
const getUTMParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
        'UTM source': searchParams.get('utm_source') || undefined,
        utm_medium: searchParams.get('utm_medium') || undefined,
        utm_campaign: searchParams.get('utm_campaign') || undefined,
        utm_term: searchParams.get('utm_term') || undefined,
        utm_content: searchParams.get('utm_content') || undefined,
    };
};
export const trackPageView = (eventName, properties = {}) => {
    const utmParams = getUTMParams();
    mixpanel.track(eventName, {
        ...properties,
        ...utmParams
    });
}
