import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom";
import useResponsive from '../hooks/useResponsive';
import MiniLogo from '../asset/svg/MiniLogo';
import MobileLockImg from '../asset/svg/mobileLockImg.png';
import { EXTERNAL_LINKS } from '../constent/links';

const MobileLineSVG = () => {
    return (
        <svg width="360" height="117" viewBox="0 0 360 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M251.449 -494.355L419.072 -153.813C438.64 -114.059 417.415 -66.2897 374.796 -54.1668L90.5154 26.6967C29.5764 44.0307 -19.8674 -24.0529 15.4717 -76.638L316.603 -524.725" stroke="url(#paint0_linear_173_1926)" stroke-width="16" />
            <defs>
                <linearGradient id="paint0_linear_173_1926" x1="16.266" y1="-310.657" x2="880.203" y2="-146.927" gradientUnits="userSpaceOnUse">
                    <stop offset="0.100482" stopColor="#D6D0FF" />
                    <stop offset="0.33" stopColor="#F7AFF1" />
                    <stop offset="0.635" stopColor="#FFFBF7" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const MobileLock = () => {
    return (
        <div className="bg-star-light4 border h-dvh ">
            <div className="fixed top-0 w-full left-0 ">
                <MobileLineSVG />
            </div>
            <div className="flex items-center flex-col justify-center text-center mt-5">
                <div className="py-2">
                    <MiniLogo />
                </div>
                <p className="text-headingThree text-space-default">Welcome aboard!</p>
                <p className="text-subHeadingThree bg-clip-text text-transparent bg-heading-gradient-3">Your account is all set up.</p>
                <p className='text-bodyOne text-space-default mt-8 w-[90%]'>
                    We have sent a one-time <span className='text-star-default font-bold'>magic link</span>  to email  to log into your account on the <span className='text-star-default font-bold'>desktop</span>.
                </p>
            </div>
            <div className='overflow-hidden'>
                <div className=''>
                    <img src={MobileLockImg} className='w-full' alt="mobile-lock" />
                </div>
            </div>
            <div className="fixed z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >
                <a href={EXTERNAL_LINKS.website} className="filled-btn small w-full">Okay</a>
            </div>
        </div>
    )
}

const AuthGuard = ({ children }) => {
    const authState = useSelector((state) => state.auth);
    const { isMobile } = useResponsive();

    const isAuthenticated = authState.isLoggedIn;
    const onboardingStep = authState.onboardingStep;
    const isLinkedinVerified = authState.isLinkedinVerified;

    const { pathname } = useLocation();

    // If not login
    if (!isAuthenticated) {
        return <Navigate to='/login' />;
    }
    // If login & onboarding is not complete
    if (onboardingStep < 3 && pathname !== '/onboarding') {
        return <Navigate to='/onboarding' />;
    }
    // If login & onboarding is complete
    if (onboardingStep > 3 && pathname.includes('onboarding')) {
        return <Navigate to='/' />;
    }

    // if (!isLinkedinVerified && !pathname.includes('onboarding')) {
    //     return <Navigate to='/onboarding' />;
    // }
    // if (isMobile) {
    //     return (
    //         <MobileLock />
    //     )
    // }
    return <>{children}</>;
};

export default AuthGuard;
