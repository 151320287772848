import { useEffect, useState } from "react";
import { createBookmark, getCollectionsWithPostId, removeBookmark, storeBookmark } from "../services/bookmark.service";
import { useDispatch } from "react-redux";
import { addPostId, removePostId, setPostIds } from "../redux/slice/post.slice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { fetchBookmarkIds } from "../services/post.service";
import { zodResolver } from "@hookform/resolvers/zod";
import DialogBox from "./DialogBox";
import { ArrowLeft, CheckCircleSolid, PlusCircle, Xmark } from "iconoir-react";
import posts from "../asset/svg/posts.svg"
import moment from "moment";
import InputField from "../../src/component/form/InputField";

const BookmarkDialog = ({ handleBookmark, postId, source, bookmarkIds }) => {
    const [createTab, setCreateTab] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        const storeBookmarkAsync = async () => {
            try {
                await storeBookmark({ postId, source });
                handleAddPost(postId)
                toast.success('Added to Bookmarks');
                refetch();
            } catch (error) {
                console.error("Error storing bookmark:", error);
            }
        };

        if (!bookmarkIds.includes(postId)) {
            storeBookmarkAsync();
        }

    }, [postId, source]); // Add dependencies to rerun if postId or source changes

    const dispatch = useDispatch();

    const handleAddPost = (postId) => {
        dispatch(addPostId(postId));
    };

    const handleRemovePost = async (postId) => {
        const response = await fetchBookmarkIds();
        dispatch(removePostId(postId));
        dispatch(setPostIds(response.data));
    };

    const handleLabelCreation = () => {
        handleBookmark()
        handleAddPost(postId)
        toast.success('Added to Bookmarks');
    }

    const labelSchema = z.object({
        label: z.string()
            .max(25, "Label should not exceed 25 characters")
            .min(4, "Label must be at least 4 characters"),
    });

    const { mutate, isPending } = useMutation({
        mutationFn: createBookmark,
        onSuccess: () => handleLabelCreation(),
        onError: () => {
            setError("label", { type: 'validate', message: 'Invalid label' });
            toast.error('Something went wrong');
        },
    });


    const query = { postId };
    const { data: collectionList = [], isLoading, refetch } = useQuery({
        queryKey: ['bookmark-collections', query],
        queryFn: ({ queryKey }) => getCollectionsWithPostId(queryKey[1]),
    });

    const { handleSubmit, control, watch, setError } = useForm({
        resolver: zodResolver(labelSchema),
        defaultValues: { label: '' },
    });

    const onSubmit = (data) => {
        mutate({ label: data.label, source, postId });
    };



    const BookmarkStorekHandler = async (data) => {
        try {
            await storeBookmark(data);
            handleAddPost(data?.postId)
            handleBookmark();
            toast.success('Added to Bookmarks');
            refetch();
        } catch (error) {
            toast.error(error?.message || 'Something went wrong');
        }
    }

    const BookmarkRemovekHandler = async (data) => {
        try {
            await removeBookmark(data);
            handleRemovePost(data?.postId)
            handleBookmark();
            toast.success('Removed from Bookmarks');
            refetch();
            queryClient.invalidateQueries({ queryKey: ['bookmark-posts'] })
        } catch (error) {
            toast.error(error?.message || 'Something went wrong');
        }
    }

    return (
        <DialogBox>
            <div className="fixed inset-0 flex items-center justify-center bg-custom-light backdrop-blur-sm bg-opacity-50 z-50">
                {!createTab ? (
                    <div className="bg-gray-moon w-[530px] min-h-[500px] rounded-3xl shadow-lg px-8 pt-9 pb-5">
                        <div className="flex justify-between mb-6 items-center">
                            <p className="text-subHeadingTwoBold">Add to your collection</p>
                            <div className="text-btn small">
                                <Xmark onClick={handleBookmark} className="cursor-pointer" />
                            </div>
                        </div>

                        <div className="space-y-2 h-96 overflow-y-auto scrollbar">
                            {/* All Posts Section */}
                            <div
                                className="bg-white group mr-2 rounded-2xl flex items-center px-3 py-3 border border-transparent hover:border-star-dark cursor-pointer"
                                onClick={() =>
                                    collectionList?.AllPostData?.postExists
                                        ? BookmarkRemovekHandler({ collectionId: null, postId })
                                        : BookmarkStorekHandler({ postId, source })
                                }
                            >
                                <div className="bg-slate-100 mr-4 px-3 py-3 rounded-lg">
                                    <img src={posts} alt="img" />
                                </div>
                                <div className="flex-1">
                                    <p className="text-bodyOneBold">All Posts</p>
                                    <p className="text-bodyTwo mb-3 text-[#5E5A73]">
                                        {collectionList?.AllPostData?.totalPostCount || 0} posts
                                    </p>
                                    <p className="text-subText text-[#5E5A73]">
                                        {collectionList?.AllPostData?.latestUpdatedAt
                                            ? moment(collectionList?.AllPostData?.latestUpdatedAt).fromNow()
                                            : ""}
                                    </p>
                                </div>
                                {collectionList?.AllPostData?.postExists ? (
                                    <div
                                        className="border-none p-2 rounded-full group-hover:bg-hover-gradient-3"
                                    // onClick={() => BookmarkRemovekHandler({ collectionId: null, postId })}
                                    >
                                        <CheckCircleSolid className="cursor-pointer" width={36} height={36} color="#5F38DB" />
                                    </div>
                                ) : (
                                    <div
                                        className="group-hover:bg-hover-gradient-3 p-2 rounded-full border-none"
                                    // onClick={() => BookmarkStorekHandler({ postId, source })}
                                    >
                                        <CheckCircleSolid className="cursor-pointer" width={36} height={36} color="#D6D0FF" />
                                    </div>
                                )}
                            </div>

                            {/* Collections Section */}
                            {!isLoading ? (
                                collectionList.data.length > 0 ? (
                                    collectionList.data.map((obj) => (
                                        <div
                                            key={obj.id}
                                            className="bg-white mr-2 group rounded-2xl flex items-center px-3 py-3 border border-transparent hover:border-star-dark"
                                            onClick={() =>
                                                obj.bookmarks.length > 0
                                                    ? BookmarkRemovekHandler({ collectionId: obj._id, postId })
                                                    : BookmarkStorekHandler({
                                                        collectionId: obj._id,
                                                        postId,
                                                        label: obj.label,
                                                        source,
                                                    })
                                            }
                                        >
                                            <div className="bg-slate-100 mr-4 px-3 py-3 rounded-lg">
                                                <img src={posts} alt="img" />
                                            </div>
                                            <div className="flex-1">
                                                <p className="text-bodyOneBold">{obj.label}</p>
                                                <p className="text-bodyTwo text-[#5E5A73] mb-3">{obj.count} posts</p>
                                                <p className="text-subText text-[#5E5A73]">
                                                    {moment(obj.updatedAt).fromNow()}
                                                </p>
                                            </div>
                                            {obj.bookmarks.length > 0 ? (
                                                <div
                                                    className="border-none p-2 rounded-full group-hover:bg-hover-gradient-3"
                                                // onClick={() =>
                                                //     BookmarkRemovekHandler({ collectionId: obj._id, postId })
                                                // }
                                                >
                                                    <CheckCircleSolid className="cursor-pointer" width={36} height={36} color="#5F38DB" />
                                                </div>
                                            ) : (
                                                <div
                                                    className="group-hover:bg-hover-gradient-3 p-2 rounded-full border-none"
                                                // onClick={() =>
                                                //     BookmarkStorekHandler({ collectionId: obj._id, postId, label: obj.label, source, })
                                                // }
                                                >
                                                    <PlusCircle className="cursor-pointer" width={35} height={35} color="#7145FD" />
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div className="py-8 px-8">
                                        <p className="text-center text-gray-6">
                                            Create a new collection to save posts and access them easily.
                                        </p>
                                    </div>
                                )
                            ) : (
                                <div className="py-8 px-8 justify-center text-center items-center">
                                    <p>Loading collections...</p>
                                </div>
                            )}
                        </div>

                        <div className="pt-6 flex justify-center">
                            <button className="filled-btn small text-base" onClick={() => setCreateTab(true)}>
                                Create new collection
                            </button>
                        </div>
                    </div>

                ) : (
                    <div className="bg-gray-moon w-[530px] max-h-screen rounded-3xl shadow-lg px-8 pt-9 pb-5">
                        <div className="flex justify-between mb-4 items-center">
                            <p className="text-subHeadingTwoBold">Create a new collection</p>
                            <div className="text-btn small">
                                <Xmark onClick={() => [handleBookmark(), setCreateTab(false)]} className="cursor-pointer" />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mt-2.5">
                                <p className="text-bodyThree text-space-default mb-1">Name</p>
                                <InputField name="label" type="text" control={control} placeholder="Label" maxLength={25} />
                                <p className="text-end text-gray-6 text-subText mt-2">{25 - watch("label").length} Characters</p>
                            </div>
                            <div className="pt-6 flex justify-between">
                                <button className="text-btn small" type="button" onClick={() => setCreateTab(false)}>
                                    <ArrowLeft /> Back
                                </button>
                                <button className="filled-btn small text-base" type="submit">
                                    Create new collection
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </DialogBox>
    );
};

export default BookmarkDialog;