import { Xmark } from 'iconoir-react';
import PaymentSucess from '../../asset/svg/PaymentSucess.svg';
import { Link } from 'react-router-dom';

const LeftSvg = () => {
    return (
        <svg width="138" height="335" viewBox="0 0 138 335" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.65503 3.78198L110.922 115.764C137.954 144.25 138.436 188.765 112.026 217.829L9.49057 330.667" stroke="url(#paint0_linear_15_61)" stroke-width="11" />
            <defs>
                <linearGradient id="paint0_linear_15_61" x1="31.2993" y1="286.735" x2="199.146" y2="134.235" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const RightSvg = () => {
    return (
        <svg width="186" height="60" viewBox="0 0 186 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.29876 46.674C-0.426666 49.174 0.201193 52.5993 2.70112 54.3247C5.20107 56.0502 8.62641 55.4223 10.3518 52.9224L1.29876 46.674ZM109.507 15.0567L112.097 10.2046L109.507 15.0567ZM185.756 49.5227L112.097 10.2046L106.918 19.9087L180.576 59.2268L185.756 49.5227ZM9.23181 35.18L1.29876 46.674L10.3518 52.9224L18.2849 41.4283L9.23181 35.18ZM112.097 10.2046C76.445 -8.82623 32.1881 1.91919 9.23181 35.18L18.2849 41.4283C38.0648 12.7697 76.1981 3.51103 106.918 19.9087L112.097 10.2046Z" fill="url(#paint0_linear_15_60)" />
            <defs>
                <linearGradient id="paint0_linear_15_60" x1="-1.97575" y1="68.576" x2="214.743" y2="-2.45139" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8773FF" />
                    <stop offset="0.505" stop-color="#FFC0FA" />
                    <stop offset="1" stop-color="#FBDEBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
const PaymentSuccessModel = ({ amount, onClose }) => {
    return (
        <div className='h-screen top-0 z-[99] flex justify-center items-center w-screen fixed'>
            <div className="h-screen top-0 z-10 w-full absolute blur-xl bg-[#6A65A785]" />
            <div className="bg-white rounded-3xl p-8 z-30 relative flex items-center text-center flex-col w-[625px] overflow-hidden ">
                <div className='absolute -left-[60px] -bottom-[100px] z-10 '>
                    <LeftSvg />
                </div>
                <div className='absolute -right-1  top-[50px] z-10'>
                    <RightSvg />
                </div>
                {
                    onClose && (
                        <div className="flex justify-end w-full z-20">
                            <button className='icon-outline-btn small border-none' onClick={onClose}>
                                <Xmark />
                            </button>
                        </div>
                    )
                }

                <div className='w-[265px]'>
                    <img src={PaymentSucess} alt='payment-success' className='w-full h-full' />
                </div>
                <p className='bg-clip-text text-transparent bg-heading-gradient-3 text-bodyOneBold'>Congrats! Payment Successful</p>
                <p className='text-gray-6 text-bodyTwo'>Your payment of {amount} has been processed </p>
                <Link to={'/'} className='filled-btn mt-8'>
                    Go to Viral Content Library
                </Link>
            </div>
        </div>
    )
}
export default PaymentSuccessModel;