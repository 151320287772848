const LinkedinIconWithText = ({ className = 'md:w-[220px] w-[161px]' }) => {
    return (
        <svg className={className} viewBox="0 0 161 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_880_4456)">
                <path fillRule="evenodd" clipRule="evenodd" d="M157.764 0.211273L123.676 0.211273C122.046 0.211273 120.661 1.5008 120.661 3.08937V37.3313C120.661 38.9217 121.57 40.2113 123.2 40.2113H157.288C158.92 40.2113 160.661 38.9217 160.661 37.3313V3.08937C160.661 1.5008 159.396 0.211273 157.764 0.211273Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M157.764 0.211273L123.676 0.211273C122.046 0.211273 120.661 1.5008 120.661 3.08937V37.3313C120.661 38.9217 121.57 40.2113 123.2 40.2113H157.288C158.92 40.2113 160.661 38.9217 160.661 37.3313V3.08937C160.661 1.5008 159.396 0.211273 157.764 0.211273ZM135.899 15.4494H141.284V18.1941H141.343C142.164 16.7141 144.589 15.2113 147.587 15.2113C153.341 15.2113 154.947 18.2665 154.947 23.9256V34.497H149.233V24.9675C149.233 22.4341 148.221 20.2113 145.855 20.2113C142.983 20.2113 141.614 22.156 141.614 25.3484V34.497H135.899V15.4494ZM126.375 34.497H132.09V15.4494H126.375V34.497ZM132.804 8.7827C132.804 9.25164 132.712 9.71603 132.533 10.1493C132.353 10.5827 132.091 10.9764 131.759 11.3081C131.428 11.6398 131.034 11.903 130.601 12.0826C130.168 12.2622 129.703 12.3547 129.234 12.3548C128.766 12.3549 128.301 12.2627 127.868 12.0833C127.435 11.904 127.041 11.641 126.709 11.3095C126.378 10.9779 126.114 10.5843 125.935 10.1511C125.755 9.71788 125.663 9.25354 125.663 8.7846C125.663 7.83777 126.039 6.92968 126.708 6.26005C127.378 5.59043 128.286 5.2141 129.233 5.21385C130.179 5.2136 131.088 5.58944 131.757 6.25871C132.427 6.92798 132.804 7.83587 132.804 8.7827Z" fill="#0A66C2" />
                <path d="M114.947 34.497H109.613V32.2284H109.556C108.37 33.6037 106.309 34.7351 102.815 34.7351C98.0535 34.7351 93.9525 31.1522 93.9525 24.9941C93.9525 19.1275 97.9944 15.2113 102.994 15.2113C106.09 15.2113 108.012 16.3122 109.175 17.7179H109.233V5.92557H114.947V34.497ZM104.431 19.497C101.164 19.497 99.3278 21.8265 99.3278 24.9237C99.3278 28.0227 101.164 30.4494 104.431 30.4494C107.701 30.4494 109.613 28.0856 109.613 24.9237C109.613 21.6741 107.701 19.497 104.431 19.497ZM91.4439 31.4284C90.0954 33.1751 87.3182 34.7351 82.8344 34.7351C76.8535 34.7351 73.0439 30.7732 73.0439 24.5503C73.0439 19.0208 76.4935 15.2113 83.002 15.2113C88.623 15.2113 92.0897 18.9027 92.0897 25.396C92.0897 26.0437 91.9849 26.6875 91.9849 26.6875H78.4344L78.4668 27.276C78.842 28.9179 80.082 30.9256 82.9278 30.9256C85.4116 30.9256 87.1144 29.5903 87.8839 28.5446L91.4439 31.4284ZM86.5906 23.0665C86.6287 21.0589 85.1544 19.0208 82.8306 19.0208C80.0649 19.0208 78.5887 21.1808 78.4344 23.0684H86.5906V23.0665Z" fill="#0A66C2" />
                <path d="M73.0421 15.4494H66.3754L59.7087 23.0684V5.92555H53.9945V34.497H59.7087V24.9732L66.7545 34.497H73.5011L65.423 24.0836L73.0421 15.4494ZM31.1373 15.4494H36.5221V18.1941H36.5811C37.4021 16.7141 39.8268 15.2113 42.8249 15.2113C48.5792 15.2113 50.1849 18.8627 50.1849 23.9255V34.497H44.4707V24.9674C44.4707 22.1636 43.4592 20.2113 41.0935 20.2113C38.2211 20.2113 36.8516 22.4855 36.8516 25.3484V34.497H31.1373V15.4494ZM21.6135 34.497H27.3278V15.4494H21.6135V34.497ZM24.4726 12.2113C24.9295 12.222 25.3839 12.1413 25.8092 11.9739C26.2345 11.8065 26.622 11.5558 26.949 11.2364C27.276 10.9171 27.5358 10.5356 27.7132 10.1143C27.8906 9.69312 27.9819 9.24069 27.9819 8.78364C27.9819 8.3266 27.8906 7.87416 27.7132 7.45294C27.5358 7.03172 27.276 6.65022 26.949 6.33087C26.622 6.01152 26.2345 5.76077 25.8092 5.59336C25.3839 5.42595 24.9295 5.34525 24.4726 5.35602C23.5775 5.37713 22.7262 5.74751 22.1006 6.38802C21.475 7.02852 21.1248 7.88832 21.1248 8.78364C21.1248 9.67896 21.475 10.5388 22.1006 11.1793C22.7262 11.8198 23.5775 12.1902 24.4726 12.2113ZM6.37542 5.92555H0.661133L0.661133 34.497H17.804V28.7827H6.37542V5.92555Z" fill="#0A66C2" />
            </g>
            <defs>
                <clipPath id="clip0_880_4456">
                    <rect width="160" height="40" fill="white" transform="translate(0.661133 0.211273)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkedinIconWithText;