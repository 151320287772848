import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";

const getHTML = (editorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

export const copyToClipboard = (editorState) => {
  try {
    navigator.clipboard.writeText(editorState.getCurrentContent().getPlainText());
    toast.success('Copied');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
    toast.error('Unable to copy to clipboard');
  }
};



export function unsecuredCopyToClipboard(text) {
  try {
    navigator.clipboard.writeText(text);
    toast.success('Copied');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
    toast.error('Unable to copy to clipboard');
  }
}