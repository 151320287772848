import { ArrowLeft, EditPencil, FastArrowLeft, FastArrowRight, Sparks } from "iconoir-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LightBulbIcon from "../../asset/svg/LightBulbIcon";
import ThumbsUpIcon from "../../asset/svg/ThumbupIcon";
import ContentExpand from "../../component/ContentExpand";
import '../../style/choiceScreen.css';
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import PERMISSIONS, { hasPermission } from "../../utils/permissions";
import { useNavigate } from "react-router-dom";

const LeftSide = ({ content, selectedComment }) => {
    const [show, setShow] = useState(true);
    const handleToggle = () => {
        setShow(prev => !prev);
    }
    return (
        <div className="rounded-[20px] p-4 overflow-auto h-[95vh] scrollbar" style={{
            background: 'rgba(245, 242, 255, 0.40)'
        }}>
            <div className="flex items-center gap-1">
                <LightBulbIcon />
                <p className="text-bodyOneBold text-star-dark">Your Inspiration</p>
                {
                    show && (
                        <div className="flex gap-2 text-bodyTwo text-star-default items-center cursor-pointer ml-auto" onClick={handleToggle}>
                            <p>Hide</p>
                            <FastArrowLeft />
                        </div>
                    )
                }
            </div>
            {
                !show && (
                    <div className="flex gap-2 justify-end text-bodyTwo text-star-default items-center cursor-pointer mt-2" onClick={handleToggle}>
                        <p>Show</p>
                        <FastArrowRight />
                    </div>
                )
            }
            {
                show && (
                    <>
                        <p className="text-bodyTwo text-space-default my-4">
                            Reference post
                        </p>
                        <div className="rounded-xl bg-white p-4">
                            <ContentExpand content={content} maxLines={3} />
                        </div>
                        <div className="mt-4">
                            {
                                selectedComment?.length > 0 && (
                                    <p className="text-bodyTwo text-space-default">Interesting comments</p>
                                )
                            }
                            <div className="flex flex-col mt-4 gap-4">
                                {
                                    selectedComment?.map((comment) => (
                                        <div key={comment._id} className="rounded-xl p-2 bg-star-light4 flex gap-2">
                                            <div className="min-w-5">
                                                <ThumbsUpIcon firstColor="#5F38DB" secondColor="#A489F3" />
                                            </div>
                                            <p className="text-bodyTwo text-gray-6">
                                                {comment?.commentText}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}


const ChoiceScreen = (props) => {
    const { subscriptionLimit } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {
        postId: _id,
        onClose,
        content,
        // handleBack,
        handleNext,
        handleGenerateNewPost,
        handleWriteMannual,
        // source
    } = props;


    const trackBackClicked = () => {
        trackEvent(MIXPANEL_EVENTS.PREVIEW_BACK, {
            "Post Id": props.postId,
            "Generated with AI": props.isAiGenerated,
            "Manual changes": !props.isAiGenerated
        })
    }


    const generatePermission = hasPermission(PERMISSIONS.AI_GENERATE);
    const remainingCount = (subscriptionLimit.aiCreditLimit - subscriptionLimit.aiCreditUsage);
    const remainingText = remainingCount === subscriptionLimit.aiCreditLimit ? remainingCount - 1 : remainingCount;

    const trackManulClicked = () => {
        trackEvent(MIXPANEL_EVENTS.MANUALLY_CLICKED, {
            "Post ID": _id,
            "Post source": props.source,
            "Remaining AI credits": remainingCount
        })
    }
    const trackAIClicked = () => {
        trackEvent(MIXPANEL_EVENTS.AI_CLICKED, {
            "Post ID": _id,
            "Post source": props.source,
            "Remaining AI credits": remainingCount,
            "AI chosen stage": 'split_screen',
            // "Manual before AI": false,
        })
    }

    const upgradeRequired = !generatePermission || remainingText <= 0;
    return (
        <div className="z-50 h-screen w-screen fixed top-0 left-0 p-5 bg-star-light2">
            <div className="flex  gap-5 h-[95vh]">

                {/* Left Side */}
                <div className="max-w-[32%]">
                    <LeftSide content={content} />
                </div>

                {/* Right Side */}
                <div className="flex flex-1 flex-col gap-5 rounded-[20px] bg-gray-moon p-[30px] h-[95vh]">
                    <div className="flex items-center justify-center gap-2">
                        <div className="rounded-2xl bg-star-default w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                        <div className="rounded-2xl bg-gray-3 w-[52px] h-[5px]" />
                    </div>
                    <div>
                        <p className="text-subHeadingTwoBold bg-clip-text text-transparent bg-hover-gradient-1">
                            Let’s start writing!
                        </p>
                        <p className="text-bodyOne">
                            Take inspiration from the reference post. Make it stronger using comments you agree with...
                        </p>
                    </div>

                    <div className="mt-5 h-[380px] flex gap-2">
                        <div className={`flex-1 flex flex-col rounded-lg items-center justify-center border border-gray-3 h-full cursor-pointer ai-screen `} onClick={() => {

                            if (upgradeRequired) {
                                navigate('/payment-pending')
                            } else {
                                handleGenerateNewPost();
                                handleNext();
                                trackAIClicked();
                            }
                        }}>
                            <div className="rounded-xl flex items-center gap-2 py-2 px-4 bg-base-gradient  text-gray-moon button ">
                                <Sparks width={20} />
                                <p className="text-buttonSmall ">{upgradeRequired ? 'Upgrade to generate With AI' : 'Generate With AI'}</p>
                            </div>
                            <div className="text-gray-5 flex gap-1 mt-2 items-center paragraph">

                                {
                                    generatePermission ? (
                                        <>
                                            <p className="text-bodyThree ">
                                                <b>{remainingText}</b> of {subscriptionLimit.aiCreditLimit - 1} credits left
                                            </p>
                                            <p className="text-bodyThree ">• powered by ChatGPT</p>
                                        </>
                                    ) :
                                        (
                                            <>
                                                <p className="text-bodyThree">
                                                    powered by ChatGPT
                                                </p>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                        <div className="text-star-default  hover:bg-base-gradient hover:text-white cursor-pointer transition-all duration-200 flex-1 rounded-lg border border-gray-3 bg-star-light3 h-full" onClick={() => {
                            handleWriteMannual();
                            handleNext();
                            trackManulClicked();
                        }}>
                            <div className="flex flex-col h-full items-center justify-center">
                                <div className="flex items-center gap-2 ">
                                    <EditPencil width={20} />
                                    <p className="text-buttonSmall">Write Manually</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center mt-auto">
                        <button className="text-btn small" onClick={() => {
                            onClose();
                            trackBackClicked();
                        }}> <ArrowLeft /> Back</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChoiceScreen;