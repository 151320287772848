const DialogBox = ({ children }) => {
    return (
        <div className="fixed z-[111] top-0 left-0 w-full h-full">
            <div className="absolute top-0 left-0 z-0 w-full h-full blur-[7.5]" style={{ background: 'rgba(106, 101, 167, 0.52)', }} />
            <div className="relative  h-full z-10 flex items-center justify-center">
                {children}
            </div>
        </div>
    )
}
export default DialogBox;