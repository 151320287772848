import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";

const LogoutRequiredGuard = ({ children }) => {
    const authState = useSelector((state) => state.auth);
    const isAuthenticated = authState.isLoggedIn;

    if(isAuthenticated){
        return <Navigate to='/'/>
    }
    return <>{children}</>;
};

export default LogoutRequiredGuard;
