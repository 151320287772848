import moment from "moment/moment";
import BadgeCheckFilled from "../asset/svg/BadgeCheckFilledIcon";
import SoloLogo from "../asset/svg/SoloLogo";
import { useSelector } from "react-redux";
import { trackEvent } from "../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../utils/mixpanel/eventType";

const PlanCard = (props) => {
    const authState = useSelector((state) => state.auth);

    const subscription = authState.subscription;
    const isFreeTrial = authState.subscription.isFreeTrial;

    const {
        isActive,
        className,
        name,
        billingPeriod,
        amount,
        amountBeforeDiscount,
        features,
        _id,
        currency,
        description,
        index = 0,
        handlePayment,
        isPending
    } = props;

    const currencySymbol = currency === 'INR' ? '₹' : '$';
    const tenure = billingPeriod === 'monthly' ? '/month' : '/month for 12 months';

    const theme = [
        {
            color: '#2789EC',
            background: '#F0FAFF',
        }, {
            color: '#7145FD',
            background: '#F7F6FF',
        }, {
            color: '#FF90F6',
            background: '#FFF4FE',
        }
    ]

    const { color, background } = theme[index];
    const isBest = isActive ? false : index === 0;
    const actualAmount = billingPeriod === 'yearly' ? (amount / 12)?.toLocaleString() : amount?.toLocaleString();
    const isCancelled = subscription?.status === 'cancelled'
    return (
        <div className={`relative border flex-1 flex flex-col  rounded-[20px] px-[26px] py-9 ${className} max-w-[385px]`} style={{ borderColor: color, background: background }}>
            {isActive && <div className="absolute -top-[15px] left-0 right-0  m-auto rounded-lg text-white w-[fit-content] bg-star-default px-3 py-1">
                Current Plan 👇
            </div>}
            {
                isBest && <div className="absolute -top-[50px] left-0 right-0 w-full -z-10 rounded-[20px] text-white text-center bg-star-default  pt-4 py-10">
                    <p className="text-bodyOneBold md:text-headingFour">Best Value 🚀
                    </p>
                </div>
            }
            {/* <div className="absolute -top-[16px] right-0 w-[214px] h-[207px]">
                <SoloLogo className={'w-full h-full'} />
            </div> */}
            <p className="text-headingThree " style={{ color: color }}>{name}</p>
            <p className="text-bodyTwo text-nowrap text-gray-6">{description}</p>
            <p className="text-subHeadingTwo font-semibold text-gray-5 mt-8"> <del>{currencySymbol}{amountBeforeDiscount?.toLocaleString()}</del></p>
            <div className="flex items-center gap-2">
                <p className="text-headingTwo text-space-default">{currencySymbol}{actualAmount}  </p>
                <span className="text-bodyTwo text-gray-5">{tenure}</span>
            </div>
            {/* <p className="text-bodyOne text-gray-5">+ Taxes</p> */}
            <div className="mt-5">
                <p className="text-bodyTwoBold " style={{ color: color }}>Get started with</p>
                <div className="flex mt-4 flex-col gap-2">
                    {
                        features?.map((item, index) => (
                            <div key={index} className="flex items-start gap-2">
                                <div className="mt-[1px]">
                                    <BadgeCheckFilled color={color} />
                                </div>
                                <p className="text-bodyOne text-gray-6">{item.title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                isActive && subscription.endDate && (
                    <div className="flex flex-col mt-8 items-center justify-center">
                        <p className="text-bodyThree text-gray-5">Subscription  {isCancelled ? 'ends on' : 'Renewal Date'}</p>
                        <p className="text-bodyOneBold">{moment(subscription.endDate).format("DD MMM'YY")}</p>
                    </div>
                )
            }
            <p className="text-bodyTwo text-gray-6 pt-5 text-center mt-auto mb-4"> Cancel anytime, don't get charged next month
            </p>
            {
                isFreeTrial && handlePayment && (
                    <div className="flex flex-col  items-center justify-center">
                        <button disabled={isPending} className={`filled-btn ${color === '#2789EC' ? 'blue' : ''} `} onClick={() => {
                            handlePayment(_id)
                            trackEvent(MIXPANEL_EVENTS.PAYMENT_INITIATED, {
                                'Timeline': billingPeriod,
                                'Plan chosed': name
                            })
                        }}>Get started</button>
                    
                    </div>
                )
            }
            {
                !isFreeTrial && !isActive && (
                    <div className="flex items-center justify-center ">
                        <p className="text-button text-center" style={{ color: color }}><span className="font-semibold">Contact us</span>  <br /> <span className="font-bold">hello@superpen.io</span></p>
                    </div>
                )
            }
        </div>
    )
}
export default PlanCard;