const LinkedinRepost = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_568_16299)">
                <path d="M14.7933 5.5H6.83325C6.28325 5.5 5.83325 5.95 5.83325 6.5V16.5H3.83325V6.5C3.83325 4.84 5.17325 3.5 6.83325 3.5H14.7933L12.8333 0.5H15.2033L17.8333 4.5L15.2033 8.5H12.8333L14.7933 5.5ZM20.3333 8.5H19.8333V18.5C19.8333 19.05 19.3833 19.5 18.8333 19.5H10.8733L12.8333 16.5H10.4633L7.83325 20.5L10.4633 24.5H12.8333L10.8733 21.5H18.8333C20.4933 21.5 21.8333 20.16 21.8333 18.5V8.5H20.3333Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_568_16299">
                    <rect width="24" height="24" fill="white" transform="translate(0.833252 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkedinRepost;