import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { ArrowLeft, Mail } from "iconoir-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import StrokeBackgroundImage from '../../asset/svg/auth-stroke.svg';
import InputField from "../../component/form/InputField";
import Header from "../../layout/auth/Header";
import { sendPasswordResetLink } from "../../services/auth.service";
import { toast } from "react-toastify";
import { trackEvent } from "../../utils/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanel/eventType";
import useResponsive from "../../hooks/useResponsive";
import MiniLogo from "../../asset/svg/MiniLogo";
const trackLoginClick = () => {
    trackEvent(MIXPANEL_EVENTS.LOGIN_CLICKED, {
        "Log in click source": "reset-password"
    })
}
const SuccessScreen = ({ email }) => {
    const { isMobile } = useResponsive();

    const { mutate, isPending } = useMutation({
        mutationFn: sendPasswordResetLink,
        onSuccess: () => {
            toast.success('Resend Successfully')
            trackEvent(MIXPANEL_EVENTS.RESET_RE_REQUESTED);
        },
        onError: (error) => {
            toast.error(error?.message || 'Something went wrong !!!')
        }
    });
    const resendLink = () => {
        if (isPending) {
            return;
        }
        mutate({ email });
    }
    return (
        isMobile ? <MobileSuccess
            email={email}
            resendLink={resendLink}
        /> : (

            <div>
                <p className="text-headingTwo text-space-default">Check your email</p>
                <p className="mt-8 text-bodyOne bg-heading-gradient-3 bg-clip-text text-transparent">Check your inbox to reset your password.            </p>
                <div className="flex gap-2 px-2.5 py-1 items-center">
                    <Mail />
                    <p className="text-subHeadingTwo text-space-default">{email}</p>
                </div>
                <p className="text-bodyTwo mt-6 text-space-default">
                    Make sure to check your spam folder! <span onClick={resendLink} className="text-bodyTwoBold text-star-light cursor-pointer underline ">Resend code</span>
                </p>
                <Link to={'/login?utm_source=reset-password'} className="filled-btn mt-2.5" onClick={trackLoginClick}>
                    Back to log in
                </Link>
            </div>
        )
    )
}

const MobileSuccess = ({ email, resendLink }) => {
    return (
        <div className="bg-star-light4 border min-h-screen">
            <div className="flex items-center px-5 flex-col justify-center text-center mt-5">
                <div className="py-2">
                    <MiniLogo />
                </div>
                <p className="text-headingThree text-space-default mb-6">Check your email</p>
                <p className="text-subHeadingThree bg-clip-text text-transparent bg-heading-gradient-3 w-[90%]">We sent a password reset link to</p>
                <div className="flex gap-2 px-2.5 py-1 items-center">
                    <Mail />
                    <p className="text-subHeadingTwo text-space-default">{email}</p>
                </div>
                <p className="text-bodyTwo mt-6 text-space-default">
                    Make sure to check your spam folder! <span onClick={resendLink} className="text-bodyTwoBold text-star-light cursor-pointer underline ">Resend code</span>
                </p>
            </div>
            <div className="fixed justify-between flex z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >
                <Link to={'/login?utm_source=reset-password'} className="text-btn small" onClick={trackLoginClick}>
                    <ArrowLeft />
                    Back to log in
                </Link>

            </div>

            <div className="fixed bottom-0 w-full right-0 ">
                <MobileViewLine />
            </div>
        </div>
    )
}

const forgetPasswordSchema = z.object({
    email: z.string().email("Invalid email address"),
});

const ForgetPassword = () => {
    const [isSent, setIsSent] = useState(false);
    const { isMobile } = useResponsive();

    const { handleSubmit, control, setError, getValues } = useForm({
        resolver: zodResolver(forgetPasswordSchema),
        defaultValues: {
            email: '',
        }
    });

    const { mutate, isPending } = useMutation({
        mutationFn: sendPasswordResetLink,
        onSuccess: () => {
            setIsSent(true);
            trackEvent(MIXPANEL_EVENTS.RESET_PW_CLICKED, {
                'Valid email for reset': true
            })
            trackEvent(MIXPANEL_EVENTS.RESET_PW_SENT);
        },
        onError: (error) => {
            setError("email", { type: 'validate', message: error?.message || 'Something went wrong' });
            trackEvent(MIXPANEL_EVENTS.RESET_PW_CLICKED, {
                'Valid email for reset': false
            })
        }
    });

    const onSubmit = (data) => {
        mutate(data);
    };

    return (
        isMobile ?
            (
                <MobileView
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    isPending={isPending}
                    control={control}
                    getValues={getValues}
                    isSent={isSent}
                />
            ) : (
                <div className="bg-star-light4 h-screen overflow-hidden">
                    <Header />
                    <div className="flex relative items-center justify-center h-5/6">
                        <div className="py-6 px-9 rounded-3xl border border-gray-3 bg-gray-moon [width:449px] z-10">
                            {
                                isSent ? <SuccessScreen {...getValues()} /> : (
                                    <div>
                                        <p className="text-headingTwo text-space-default">Forgot Password?</p>
                                        <p className="text-bodyOne bg-heading-gradient-3 bg-clip-text text-transparent">No problem! Enter your email, and we'll send a reset link
                                        </p>

                                        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                                            <div >
                                                <p className="text-bodyTwo text-space-default mb-1">Email</p>
                                                <InputField
                                                    name="email"
                                                    type='email'
                                                    control={control}
                                                    placeholder="hello@example.com"
                                                />
                                            </div>

                                            <div className="flex justify-between mt-6">
                                                <Link to={'/login?utm_source=reset-password'} className="text-btn" onClick={trackLoginClick}>
                                                    <ArrowLeft />
                                                    Back to log in
                                                </Link>
                                                <button className="filled-btn" disabled={isPending}>Reset Password</button>
                                            </div>
                                        </form>
                                    </div>
                                )
                            }

                        </div>
                        <div className="h-full w-full absolute ">
                            <img src={StrokeBackgroundImage} alt="background" className="w-full" />
                        </div>
                    </div>
                </div>
            )
    );
};

export default ForgetPassword;


const MobileView = ({ onSubmit, handleSubmit, isPending, control, getValues, isSent }) => {
    return (
        isSent ? <SuccessScreen {...getValues()} /> : (
            <div className="bg-star-light4 border min-h-dvh">
                <div className="flex items-center flex-col justify-center text-center mt-5">
                    <div className="py-2">
                        <MiniLogo />
                    </div>
                    <p className="text-headingThree text-space-default">Forgot Password?</p>
                    <p className="text-bodyOne bg-clip-text text-transparent bg-heading-gradient-3 w-[90%]">No worries, we will send you reset instructions</p>

                </div>
                <form className="mt-6 px-5" onSubmit={handleSubmit(onSubmit)}>
                    <div >
                        <p className="text-bodyTwo text-space-default mb-1">Email</p>
                        <InputField
                            name="email"
                            type='email'
                            control={control}
                            placeholder="hello@example.com"
                        />
                    </div>

                    <div className="fixed justify-between flex z-30 px-5 py-4  bottom-0 left-0 w-full bg-star-light4 border border-star-light2" >
                        <Link to={'/login?utm_source=reset-password'} className="text-btn small" onClick={trackLoginClick}>
                            <ArrowLeft />
                            Back to log in
                        </Link>
                        <button className="filled-btn small" disabled={isPending}>Reset Password</button>
                    </div>
                </form>

                <div className="fixed bottom-0 w-full right-0 ">
                    <MobileViewLine />
                </div>
            </div>
        )
    )
}

const MobileViewLine = () => {
    return (
        <svg width="360" height="182" viewBox="0 0 360 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6723 456.422L-38.066 168.296C-53.4112 90.3702 17.7742 23.093 94.7099 42.8095L292.258 93.4355C338.142 105.194 358.248 159.089 331.277 198.027L78.479 562.988" stroke="url(#paint0_linear_597_1118)" strokeWidth="16" />
            <defs>
                <linearGradient id="paint0_linear_597_1118" x1="356.45" y1="152.109" x2="14.0248" y2="-46.2313" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DCD1FF" />
                    <stop offset="0.341459" stopColor="#FFE5FD" />
                    <stop offset="1" stopColor="#F7AFF1" />
                </linearGradient>
            </defs>
        </svg>
    )
}