import { WarningCircle } from "iconoir-react";
import { useEffect, useRef, useState } from "react";

const InputField = ({ errorMessage, maxLength, ...other }) => {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);
    return (

        <div className="w-full">
            <div className="relative">
                <input className="rounded-lg text-bodyTwo text-space-default bg-gray-moon  py-2.5 px-3.5 w-full"
                    style={{
                        border: hasFocus ? '1px solid transparent' : '1px solid #E0DDED',
                        backgroundImage: hasFocus ? 'linear-gradient(#F7F6FF, #F7F6FF), linear-gradient(78deg, #22F5EF -1.83%, #7145FD 51.28%, #F7AFF1 106.56%)' : undefined,
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box',
                        outline: 'none'
                    }}
                    ref={ref}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    maxLength={maxLength}
                    {...other}
                />
                {
                    maxLength && (
                        <div className="absolute right-4 [top:17px] text-subText text-gray-4">
                            {other.value.length}/{maxLength}
                        </div>
                    )
                }

                {!!errorMessage && (
                    <div className="absolute right-4 top-2.5">
                        <WarningCircle height={'24px'} width={'24px'} color="#FD6483" />
                    </div>
                )}
            </div>
            {!!errorMessage && (
                <p className="text-bodyThree text-venus-default">{errorMessage}</p>
            )}
        </div>

    )
}
export default InputField;